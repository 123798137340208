import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsSection, EditerBox } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { CustomPopup, Popup } from "component/basic/popup";
import { FamilyItem, FamilyItemAddBtn } from "component/mypage/family";
import { BtnBox, BtnItem } from "component/basic/btns";
import { comFormat, setDateTime, setDateWeek } from "js/function";
import { DotText, FixedSection, PopupInputBox, SubInfoTextBox, TableSection, TableTd, TableTr } from "component/basic";
import { ChkBox, DatePicer, InputBoxCol2, InputItemBox, InputTap } from "component/basic/formItems";
import { OrderArea, OrderAreaTitleBox, OrderAreaTitleBtn, OrderFixedItemBox, OrderFixedItemTextBox, OrderFixedSubItemBox, OrderInputBox, OrderPage, OrderProduct, OrderProductPrice, OrderProductQty, OrderSection, OrderTablePrictBox, OrderTermsBox, OrderTermsText } from "component/product/order";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { CardAddBtn, CardItem, CardSlideControll } from "component/product/card";

const MarketOrder = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageData = location?.state ? location?.state : null;
    const type = pageData?.type;
    const options = pageData?.options;

    const open = useDaumPostcodePopup();

    const [submitChk,setSubmitChk] =  useState(true);
    const [popupData,setPopupData] =  useState(null);
    const [customPopupData,setCustomPopupData] =  useState(null);
    const [customPopupDataTerms,setCustomPopupDataTerms] =  useState(null);
    const [terms,setTerms] =  useState({});

    const [customPopupDataCard,setCustomPopupDataCard] =  useState(null);
    const [cardBtnChk,setCardBtnChk] =  useState(true);

    const [familyId,setFamilyId] =  useState(sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : null);

    const [totalPrice,setTotalPrice] =  useState(0);
    const [totalDiscount,setTotalDiscount] =  useState(0);

    const [paymentPrice,setPaymentPrice] =  useState(4000);

    const [orderData,setOrderData] =  useState({
        name:null,
        phone:null,
        addr:null,
        detailAddr:null,
        postcode:null
    });

    const [receiverOrderType,setReceiverOrderType] =  useState(false);

    const [receiverData,setReceiverData] =  useState({
        name:"",
        phone:"",
        addr:"",
        detailAddr:"",
        postcode:"",
        deliveryRequest:""
    });
    const [paymentMethod,setPaymentMethod] =  useState("CARD");

    const [cardUserData,setCardUserData] =  useState({
        number:null,
        date:null,
        pw:null,
        birth:null
    });
    const [cardId,setCardId] =  useState(null);
    const [cardApiUpdate,setCardApiUpdate] =  useState(0);

    const [subscribeDate,setSubscribeDate] =  useState({
        begin_date:null,
        end_date:null
    });
    const [dateChk,setDateChk] = useState(false);
    const [excludeDates,setExcludeDates] = useState([]);
    const [maxDate,setMaxDate] = useState(null);

    const termsApi = useGet({
        url:"/terms?type=order",
    });
    const termsData = termsApi?.data?.terms;

    const familyApi = useGet({
        url:`/family/profile`,
        loginType:"login"
    });
    const familyApiData = familyApi?.data?.user_families;

    const profileApi = useGet({
        url:`/profile`,
        loginType:"login"
    });
    const profileApiAddrDataUser = profileApi?.data?.user;
    const profileApiAddrData = profileApi?.data?.user_address;

    const cardApi = useGet({
        url: type === "subscribe" ? `/card?update=${cardApiUpdate}` : null,
        loginType:"login"
    });
    const cardData = type === "subscribe" ? cardApi?.data?.user_cards : [];

    const [cartList,setCartList] =  useState([]);

    function familyOpen(){
        setCustomPopupData({
            addClass:null,
            title:"가족 선택하기",
            closeType:true,
            closeFunc:(e) => {setCustomPopupData(null)}
        });
    }

    function receiverReSet(){
        let resetData = {
            name:"",
            phone:"",
            addr:"",
            detailAddr:"",
            postcode:"",
            deliveryRequest:""
        };
        setReceiverOrderType(false);
        setReceiverData(resetData);
    }

    function orderDataCh(key,val){
        let oldData = {...orderData};
        oldData[key] = val;
        setOrderData(oldData);
    }

    function receiverCh(key,val){
        let oldData = {...receiverData};
        oldData[key] = val;
        setReceiverData(oldData);
    }

    function cardCh(key,val){
        let oldData = {...cardUserData};
        oldData[key] = val;
        setCardUserData(oldData);
    }

    const handleCompleteReceiver = (addrItem) => {
        let oldData = {...receiverData};
        oldData.addr = addrItem.address;
        oldData.postcode = addrItem.zonecode;
        setReceiverData(oldData);
    };

    const handleCompleteOrder = (addrItem) => {
        let oldData = {...orderData};
        oldData.addr = addrItem.address;
        oldData.postcode = addrItem.zonecode;
        setOrderData(oldData);
    };
    
    const addrShOpen = (dataType) => {
        if(dataType === "receiver"){
            open({ onComplete: handleCompleteReceiver });
        }else{
            open({ onComplete: handleCompleteOrder });
        }
    };

    function orderFunc(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            if(sessionStorage.getItem("familyId")){
                formData.append("user_family_id", sessionStorage.getItem("familyId"));
            }

            //상품
            options?.forEach(function(item,i){
                if(type === "basic"){
                    formData.append("product_ids[]", item.productId);
                    formData.append("amounts[]", item.qty);
                    formData.append("option_ids[]", item.id);

                }else if(type === "cart"){
                    formData.append("product_ids[]", item.product_id);
                    formData.append("amounts[]", item.amount);
                    formData.append("cart_ids[]", item.id);
                    formData.append("option_ids[]", item.product_option_id);

                }else if(type === "subscribe"){
                    formData.append("product_ids[]", item.productId);
                    formData.append("amounts[]", item.qty);

                    formData.append("product_period_ids[]", item.periods?.id);
                    formData.append("product_week_count_ids[]", item.weekCounts?.id);
                    formData.append("product_day_count_ids[]", item.dayCounts?.id);
                }
            });

            //주문자
            formData.append("order_name", orderData?.name||"");
            formData.append("order_phone", orderData?.phone?.replace(/-/g,"")||"");
            formData.append("order_postcode", orderData?.postcode||"");
            formData.append("order_address", orderData?.addr||"");
            formData.append("order_address_detail", orderData?.detailAddr||"");

            //받는이
            formData.append("address_name", receiverData?.name||"");
            formData.append("address_phone", receiverData?.phone?.replace(/-/g,"")||"");
            formData.append("address_postcode", receiverData?.postcode||"");
            formData.append("address_address", receiverData?.addr||"");
            formData.append("address_address_detail", receiverData?.detailAddr||"");
            formData.append("address_delivery_request", receiverData?.deliveryRequest||"");

            //결제타입
            formData.append("payment_method", paymentMethod);
            
            //구독
            if(type === "subscribe"){
                formData.append("user_card_id", cardId);
                formData.append("subscribe_begin_date", subscribeDate?.begin_date);
            }
            
            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType: "login",
                url: `/order`,
                success: (data) => {
                    setSubmitChk(true);

                    setPopupData({
                        addClass:null,
                        title:"결제 완료",
                        text:"결제가 완료되었습니다.<br/>결제된 주문은 주문내역에서 확인 가능합니다.",
                        closeType:false,
                        closeFunc:(e) => {setPopupData(null);navigate("/mypage/order")},
                        btnFunc0:(e)=>{setPopupData(null);navigate("/mypage/order")},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:null,
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                }
            })
        }
    }

    function cardAdd(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            formData.append("card_no", cardUserData?.number);
            formData.append("exp_year", cardUserData?.date?.split("/")[1]);
            formData.append("exp_month", cardUserData?.date?.split("/")[0]);
            formData.append("birth", cardUserData?.birth?.replace(/\//g,""));
            formData.append("card_pw", cardUserData?.pw);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType: "login",
                url: `/card/register`,
                success: (data) => {
                    setSubmitChk(true);

                    setCardApiUpdate(cardApiUpdate + 1);

                    setCustomPopupDataCard(null);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                }
            })
        }
    }

    function cardDel(id){
        if(submitChk){
            setSubmitChk(false);

            fatchSet.FatchApi({
                type:"DELETE",
                loginType: "login",
                url: `/card/delete?user_card_id=${id}`,
                success: (data) => {
                    setSubmitChk(true);

                    let oldData = [...cartList];
                    oldData = oldData?.filter((el)=> el.id !== id);
                    setCartList(oldData);

                    setPopupData({
                        addClass:null,
                        title:"카드 삭제",
                        text:"카드 정보 삭제가 완료되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {setPopupData(null)},
                        btnFunc0:(e)=>{setPopupData(null)},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:null,
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                }
            })
        }
    }

    function dateSet(beginDate){
        let oldData = {...subscribeDate};
        if(beginDate){
            let endDate = new Date(beginDate?.replace(/-\./g,"/"));
            endDate.setDate(endDate.getDate() + 28);
 
            oldData.begin_date = beginDate;
            oldData.end_date = setDateTime(endDate,"yymmdd","hymd");
        }else{
            oldData.begin_date = null;
            oldData.end_date = null;
        }

        setSubscribeDate(oldData);
        setTimeout(() => {
            setDateChk(!dateChk);
        }, 100);
    }

    function excludeDatesSetting(monthData){
        let setData = [];
        if(monthData){
            const nowML = new Date(monthData?.split("-")[0],monthData?.split("-")[1],0);
            const nowML_date = nowML?.getDate();
            const nowDateArr = [...Array(nowML_date + 1)?.keys()]?.slice(1);

            nowDateArr.forEach((item,i)=>{
                let chkDate = `${monthData}-${item}`;
                if(!(setDateWeek(chkDate) === "수" || setDateWeek(chkDate) === "토")){
                    setData.push(new Date(chkDate.replace(/\-/g,"/")));
                }

                if(nowDateArr?.length - 1 === i){
                    setMaxDate(new Date(chkDate.replace(/\-/g,"/")));
                    setTimeout(() => {
                        setExcludeDates(setData);
                        setTimeout(() => {
                            setDateChk(!dateChk);
                        }, 100);
                    }, 100);
                }
            });

            if(!nowDateArr || nowDateArr?.length <= 0){
                setExcludeDates(setData);
                setMaxDate(null);
                setTimeout(() => {
                    setDateChk(!dateChk);
                }, 100);
            }
        }else{
            setExcludeDates(setData);
            setMaxDate(null);
            setTimeout(() => {
                setDateChk(!dateChk);
            }, 100);
        }
    }

    const termsOpen = (id,title,contents) => {
        let termsItem = {
            id:id,
            title:title,
            contents:contents
        }
        setTerms(termsItem);
        setCustomPopupDataTerms({
            addClass:"termsCustomPopup",
            title:title,
            closeType:true,
            closeFunc:(e) => {
                setTimeout(() => {
                    setCustomPopupDataTerms(null)
                }, 200);
            }
        });
    }

    useEffect(() => {
        if(cardData){
            setCartList(cardData);
            setCardId(cardData[0]?.id);
        }
    }, [cardApi]);
    
    useEffect(() => {
        let receiverSettingData = {...receiverData};

        if(receiverOrderType){
            receiverSettingData.name = orderData?.name||"";
            receiverSettingData.phone = orderData?.phone||"";
            receiverSettingData.postcode = orderData?.postcode||"";
            receiverSettingData.addr = orderData?.addr||"";
            receiverSettingData.detailAddr = orderData?.detailAddr||"";
        }else{
            receiverSettingData.name = null;
            receiverSettingData.phone = null;
            receiverSettingData.postcode = null;
            receiverSettingData.addr = null;
            receiverSettingData.detailAddr = null;
        }

        setReceiverData(receiverSettingData);
    }, [receiverOrderType]);

    useEffect(() => {
        let settingData = {...orderData};
        let receiverSettingData = {...receiverData};

        if(profileApiAddrData){
            settingData.postcode = profileApiAddrData?.postcode||"";
            settingData.addr = profileApiAddrData?.address||"";
            settingData.detailAddr = profileApiAddrData?.address_detail||"";
            receiverSettingData.deliveryRequest = profileApiAddrData?.delivery_request||"";
        }
        
        if(profileApiAddrDataUser){
            settingData.name = profileApiAddrDataUser?.name||"";
            settingData.phone = profileApiAddrDataUser?.phone||"";
        }

        setOrderData(settingData);
        setReceiverData(receiverSettingData);
    }, [profileApi]);

    useEffect(() => {
        let total = 0,
            discount = null;
        let discountOption = 0,
            discountChk = false;
        
        if(options && options?.length > 0){
            options?.forEach(function(item,i){
                if(type === "cart"){
                    total = total + ((Number(item.price) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.amount));
                    discount = discount||0 + (Number(item.discount_price) * Number(item.amount));
                }else{
                    if(item.discount_basic_price){
                        discountChk = true;
                    }

                    total = total + (Number(item.add_price) * Number(item.qty));
                    discountOption = discountOption + (Number(item.discount_basic_price) * Number(item.qty));

                    if(options?.length - 1 === i){
                        if(discountChk){
                            discount = discountOption;
                        }
                    }
                }

                if(options?.length - 1 === i){
                    setTotalPrice(total);
                    setTotalDiscount(discount);
                    setPaymentPrice(total + 4000 - discount);
                }
            })
        }else{
            setTotalPrice(0);
            setTotalDiscount(0);
            setPaymentPrice(4000);
        }
    }, [options]);

    useEffect(() => {
        if((cardUserData?.number?.length == 16 || cardUserData?.number?.length == 15)
            && cardUserData?.date?.length == 5 
            && cardUserData?.birth?.length == 8
            && cardUserData?.pw?.length == 2
        ){
            setCardBtnChk(false)
        }else{
            setCardBtnChk(true)
        }
    }, [cardUserData]);

    useEffect(() => {
        let nowDate = new Date();

        excludeDatesSetting(setDateTime(nowDate,"yymm","hymd"));
        dateSet(null);

        console.log(options)
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                mBtn={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <OrderPage>
                    <OrderSection>
                        <OrderArea>
                            <OrderAreaTitleBox addClass="notBorder" title="주문 정보"/>
                            <TableSection
                                headType={true}
                                headAddClass="pcContents"
                                th={[
                                    {
                                        style:{minWidth:"58%",width:"58%"},
                                        contents:`상품 정보`
                                    },
                                    {
                                        style:{minWidth:"18%",width:"18%"},
                                        contents:`수량`
                                    },
                                    {
                                        style:{minWidth:"24%",width:"24%"},
                                        contents:`주문 금액`
                                    }
                                ]}
                            >
                                {options && options?.length > 0 ? 
                                <>
                                {options?.map((item,i)=>(
                                    <TableTr key={i} addClass={``}>
                                        <TableTd
                                            addClass="alignLeft"
                                            style={{minWidth:"58%",width:"58%"}}
                                        >
                                            <div className="cartProductBox">
                                                <OrderProduct
                                                    image_url={type === "cart" ? item?.main_image_url : item?.productImg}
                                                    name={type === "cart" ? item?.name : item?.productName}
                                                    options={type === "cart" ? item?.option_name : type === "basic" ? item?.name : `${item?.periods?.value||"-"}주 / ${item?.weekCounts?.name} / ${item?.dayCounts?.name}`}
                                                />
                                            </div>
                                        </TableTd>
                                        <TableTd
                                            addClass="pcContents"
                                            style={{minWidth:"18%",width:"18%"}}
                                        >
                                            <OrderProductQty addClass="" qty={type === "cart" ? item?.amount : item?.qty}/>
                                        </TableTd>
                                        <TableTd
                                            style={{minWidth:"24%",width:"24%"}}
                                        >
                                            <OrderTablePrictBox>
                                                <OrderProductPrice
                                                    discountPrice={
                                                        type === "cart" ? 
                                                        item.discount_price ? `${comFormat(((Number(item.price) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.amount)) - (Number(item.discount_price) * Number(item.amount)))}원` : null
                                                        :
                                                        item.discount_price ? `${comFormat(Number(item.discount_price) * Number(item.qty))}원` : null
                                                    }
                                                    price={
                                                        type === "cart" ? 
                                                        `${comFormat((Number(item.price) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.amount))}원`
                                                        :
                                                        `${comFormat(Number(item.add_price) * Number(item.qty))}원`
                                                    }
                                                />
                                                <OrderProductQty addClass="mContents" qty={`수량 : ${type === "cart" ? item?.amount : item?.qty}`}/>
                                            </OrderTablePrictBox>
                                        </TableTd>
                                    </TableTr>
                                ))}
                                </>
                                :""}
                            </TableSection>
                        </OrderArea>
                        <OrderArea>
                            <OrderAreaTitleBox title="주문자 정보">
                                <OrderAreaTitleBtn text="가족 선택하기" func={()=>{familyOpen()}}/>
                            </OrderAreaTitleBox>
                            <OrderInputBox>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="성함"
                                        placeholder="주문하시는 분의 이름"
                                        value={orderData?.name}
                                        max={null}
                                        regexp={null}
                                        disabled={true}
                                        func={(value)=>{orderDataCh("name",value)}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="연락처"
                                        placeholder="주문하시는 분의 연락처"
                                        value={orderData?.phone}
                                        max={13}
                                        phoneSetting={true}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        disabled={true}
                                        func={(value)=>{orderDataCh("phone",value)}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="주소"
                                        placeholder="주소 검색"
                                        value={orderData?.addr}
                                        max={null}
                                        regexp={null}
                                        readOnly={true}
                                        func={(value)=>{}}
                                        clickInput={(value)=>{addrShOpen("order")}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="상세 주소"
                                        placeholder="상세 주소 입력"
                                        value={orderData?.detailAddr}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{orderDataCh("detailAddr",value)}}
                                    />
                                </InputBoxCol2>
                            </OrderInputBox>
                            <SubInfoTextBox
                                title="안내사항"
                            >
                                <DotText text="등록된 가족을 선택하신 후 주문자 정보를 입력하시면 해당 정보는 가족 정보에 자동 저장됩니다."/>
                            </SubInfoTextBox>
                        </OrderArea>
                        <OrderArea>
                            <OrderAreaTitleBox title="받아보실 분 정보">
                                <ChkBox
                                    addClass="cartAll"
                                    func={(e) => setReceiverOrderType(e.target.checked)}
                                    checkedType={receiverOrderType}
                                    label={`주문자 정보와 동일`}
                                />
                                <OrderAreaTitleBtn text="초기화" func={()=>{receiverReSet()}}/>
                            </OrderAreaTitleBox>
                            <OrderInputBox>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="수령인 성함"
                                        placeholder="수령받으실 분의 이름"
                                        value={receiverData?.name}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{receiverCh("name",value)}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="연락처"
                                        placeholder="수령받으실 분의 연락처"
                                        value={receiverData?.phone}
                                        max={13}
                                        phoneSetting={true}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{receiverCh("phone",value)}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="주소"
                                        placeholder="주소 검색"
                                        value={receiverData?.addr}
                                        max={null}
                                        regexp={null}
                                        readOnly={true}
                                        func={(value)=>{}}
                                        clickInput={(value)=>{addrShOpen("receiver")}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="상세 주소"
                                        placeholder="상세 주소 입력"
                                        value={receiverData?.detailAddr}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{receiverCh("detailAddr",value)}}
                                    />
                                </InputBoxCol2>
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="배송 요청사항 <span>(선택)</span>"
                                    placeholder="공동현관 비밀번호 등 배송 시 참고사항 작성"
                                    value={receiverData?.deliveryRequest}
                                    max={null}
                                    regexp={null}
                                    func={(value)=>{receiverCh("deliveryRequest",value)}}
                                />
                            </OrderInputBox>
                        </OrderArea>
                        <OrderArea>
                            <OrderAreaTitleBox title="결제 수단"/>
                            {type === "subscribe" ? 
                                <SubInfoTextBox
                                    title="구독 결제하기"
                                >
                                    <div className="orderCardSlide">
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={2}
                                            autoHeight={true}
                                            pagination={true}
                                            centeredSlides={true}
                                            modules={[Pagination]}
                                            onRealIndexChange={(e) => {
                                                if(cartList[e.activeIndex]){
                                                    setCardId(cartList[e.activeIndex]?.id);
                                                }else{
                                                    setCardId(null);
                                                }
                                            }}
                                            breakpoints={{
                                                1024: {
                                                    spaceBetween:20,
                                                    slidesPerView: 2
                                                },
                                                1: {
                                                    spaceBetween:14,
                                                    slidesPerView: 1.3
                                                }
                                            }}
                                        >
                                            {cartList && cartList?.map((item, i) => (
                                                <SwiperSlide key={i}>
                                                    <CardItem
                                                        addClass=""
                                                        code={item.card_code}
                                                        name={item.name}
                                                        cardNumber={item.card_number}
                                                        cardDelFunc={()=>{
                                                            setPopupData({
                                                                addClass:null,
                                                                title:"카드 삭제",
                                                                text:"카드 정보를 삭제하시겠습니까?",
                                                                closeType:true,
                                                                closeFunc:(e) => {setPopupData(null)},
                                                                btnFunc0:(e)=>{setPopupData(null)},
                                                                btn0:"취소",
                                                                btn0Type:"line",
                                                                btn1:"확인",
                                                                btn1Type:"",
                                                                btnFunc1:()=>{setPopupData(null);cardDel(item.id)}
                                                            });
                                                        }}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                            <SwiperSlide>
                                                <CardAddBtn
                                                    func={()=>{
                                                        setCustomPopupDataCard({
                                                            addClass:"cardAddCustomPopup",
                                                            title:"카드 등록",
                                                            closeType:true,
                                                            closeFunc:(e) => {
                                                                setTimeout(() => {
                                                                    setCustomPopupDataCard(null)
                                                                }, 200);
                                                            }
                                                        });
                                                    }}
                                                />
                                            </SwiperSlide>
                                            <CardSlideControll/>
                                        </Swiper>
                                    </div>
                                </SubInfoTextBox>
                            :
                                <>
                                <InputTap
                                    addClass=""
                                    data={[
                                        {
                                            id:"CARD",
                                            name:"신용/체크카드",
                                            addClass:""
                                        },
                                        {
                                            id:"VBANK",
                                            name:"가상 계좌",
                                            addClass:""
                                        }
                                    ]}
                                    val={paymentMethod}
                                    func={(value) => {setPaymentMethod(value)}}
                                />
                                {paymentMethod === "CARD" ? 
                                    <SubInfoTextBox
                                        title="결제 안내"
                                        text="💳 5만원 이상 구매 시 무이자 할부 가능<br/>■ 삼성 : 2~3개월 무이자 할부<br/>■ 현대/롯데/하나/국민/신한 : 2~3개월 무이자 할부<br/>■ 비씨 : 2~6개월 무이자 할부<br/>■ 농협 : 2~4개월 무이자 할부"
                                    />
                                    :""
                                }
                                </>
                            }
                        </OrderArea>
                    </OrderSection>
                    <FixedSection
                        pageBox="pageSizing"
                        boxAddClass="order_fixedsection"
                        addClass="order_fixedArea"
                        contentsBox="orderPage"
                        headerChk={true}
                        footerChk={true}
                        notFixed={true}
                        minSize={961}
                    >
                        {type === "subscribe" ? 
                        <>
                            <OrderFixedItemBox
                                name="구독 시작일 설정"
                            >
                                <DatePicer
                                    inputName=""
                                    placeholder=""
                                    value={subscribeDate?.begin_date}
                                    dataChk={dateChk}
                                    minCount={false}
                                    maxDate={maxDate}
                                    notTodaySel={true}
                                    inline={true}
                                    excludeDates={excludeDates}
                                    func={(date) => {
                                        dateSet(setDateTime(date,"yymmdd","hymd"));
                                    }}
                                    prevMonth={(date) => {excludeDatesSetting(date)}}
                                    nextMonth={(date) => {excludeDatesSetting(date)}}
                                />
                                <OrderFixedItemTextBox
                                    textColorClass=""
                                    name={"구독 관리기간"}
                                    val={`총 ${options[0]?.periods?.value} 주`}
                                />
                                <OrderFixedItemTextBox
                                    textColorClass=""
                                    name={"1주 당 식단 수"}
                                    val={`${options[0]?.weekCounts?.value}개`}
                                />
                                <OrderFixedItemTextBox
                                    textColorClass=""
                                    name={"1일 당 끼니 수"}
                                    val={`${options[0]?.dayCounts?.value}끼`}
                                />
                            </OrderFixedItemBox>
                            <OrderFixedSubItemBox>
                                <OrderFixedItemTextBox
                                    textColorClass=""
                                    name={"구독 시작일"}
                                    val={subscribeDate?.begin_date ? `${subscribeDate?.begin_date}(${setDateWeek(subscribeDate?.begin_date)})` : "-"}
                                />
                                <OrderFixedItemTextBox
                                    textColorClass=""
                                    name={"구독 종료일"}
                                    val={subscribeDate?.end_date ? `${subscribeDate?.end_date}(${setDateWeek(subscribeDate?.end_date)})` : "-"}
                                />
                            </OrderFixedSubItemBox>
                        </>
                        :""}
                        <OrderFixedItemBox
                            name="결제금액"
                        >
                            <OrderFixedItemTextBox
                                textColorClass=""
                                name={"총 상품 금액"}
                                val={`${comFormat(totalPrice)} 원`}
                            />
                            <OrderFixedItemTextBox
                                textColorClass=""
                                name={"총 할인 금액"}
                                val={`-${comFormat(totalDiscount)} 원`}
                            />
                            <OrderFixedItemTextBox
                                textColorClass=""
                                name={"배송비"}
                                val={`${comFormat(4000)} 원`}
                            />
                        </OrderFixedItemBox>
                        <OrderFixedSubItemBox>
                            <OrderFixedItemTextBox
                                textColorClass="oTextColor"
                                name={`<b>총 결제 금액</b>`}
                                val={`<b>${comFormat(paymentPrice)} 원</b>`}
                            />
                        </OrderFixedSubItemBox>
                        <OrderTermsBox>
                            {termsData?.map((item, i) => (
                                <OrderTermsText
                                    key={i}
                                    text={`${item.title} 동의`}
                                    chkFunc={(e) => termsOpen(item.id,item.title,item.contents)}
                                />
                            ))}
                            {type === "subscribe" ? 
                            ""
                            :
                            <>
                                <OrderTermsText
                                    text={`첫 수령일 2일 전 오전 11시 이전까지 취소할 경우 전체 환불이 가능합니다.`}
                                />
                                <OrderTermsText
                                    text={`상품이 배송 중일 경우 실결제액에서 소비한 끼니의 정가가 차감됩니다.`}
                                />
                            </>
                            }
                            <p className="orderTerms_caption">위 내용을 확인했으며, 결제에 동의합니다.</p>
                        </OrderTermsBox>
                        <BtnBox
                            boxType="fixed"
                            addClass="pcBasicBtnBox row2 orderBtnBox pcContents"
                        >
                            <BtnItem
                                addClass=""
                                contents={`${comFormat(paymentPrice)} 원 결제하기`}
                                disabled={false}
                                func={() => {
                                    orderFunc();
                                }}
                            />
                            <BtnItem
                                addClass="line"
                                contents={"취소"}
                                disabled={false}
                                func={() => {
                                    navigate(-1)
                                }}
                            />
                        </BtnBox>
                        <BtnBox
                            boxType="fixed"
                            addClass="orderBtnBox mContents"
                        >
                            <BtnItem
                                addClass=""
                                contents={`${comFormat(paymentPrice)} 원 결제하기`}
                                disabled={false}
                                func={() => {
                                    orderFunc();
                                }}
                            />
                        </BtnBox>
                    </FixedSection>
                </OrderPage>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <CustomPopup data={customPopupData}>
                <FamilyItem
                    id={null}
                    val={familyId}
                    userType={"my"}
                    name={profileApiAddrDataUser?.name}
                    gender={profileApiAddrDataUser?.gender === "f" ? "여성" : "남성"}
                    birth={`${profileApiAddrDataUser?.birth}년`}
                    height={profileApiAddrDataUser?.height}
                    weight={profileApiAddrDataUser?.weight}
                    func={()=>{
                        setFamilyId(null);
                        window.familyIdSetting("my");
                        setCustomPopupData(null);
                    }}
                />
                {familyApiData && familyApiData?.map((item,i)=>(
                    <FamilyItem
                        key={i}
                        id={item.id}
                        val={familyId}
                        userType={item.type}
                        name={item.name}
                        gender={item.gender === "f" ? "여성" : "남성"}
                        birth={`${item.birth}년`}
                        height={item.height}
                        weight={item.weight}
                        func={()=>{
                            setFamilyId(item.id);
                            window.familyIdSetting(item.id);
                            setCustomPopupData(null);
                        }}
                    />
                ))}
                <FamilyItemAddBtn func={()=>{navigate("/mypage/family")}}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
            <CustomPopup data={customPopupDataTerms}>
                <EditerBox addClass="termsText" data={terms?.contents}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="확인"
                        disabled={false}
                        func={() => {
                            setTerms({});
                            setTimeout(() => {
                                setCustomPopupDataTerms(null)
                            }, 200);
                        }}
                    />
                </BtnBox>
            </CustomPopup>
            <CustomPopup data={customPopupDataCard}>
                <PopupInputBox>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="카드 번호"
                        placeholder="“-”를 제외한 15~16자리 입력"
                        value={cardUserData?.number}
                        max={16}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{cardCh("number",value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="카드 유효기간"
                        placeholder="MM / YY"
                        value={cardUserData?.date}
                        cardDateSetting={true}
                        max={5}
                        regexp={/[^0-9\/]/gi}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{cardCh("date",value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass=""
                        inputName="카드 비밀번호"
                        placeholder="비밀번호 앞 2자리"
                        value={cardUserData?.pw}
                        max={2}
                        regexp={null}
                        func={(value)=>{cardCh("pw",value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="생년월일"
                        placeholder="YY/MM/DD"
                        value={cardUserData?.birth}
                        cardBirthSetting={true}
                        max={8}
                        regexp={/[^0-9\/]/gi}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{cardCh("birth",value)}}
                    />
                </PopupInputBox>
                <BtnBox
                    boxType="fixed"
                    addClass="col2"
                >
                    <BtnItem
                        addClass="line"
                        contents="취소하기"
                        disabled={false}
                        func={() => {
                            setCustomPopupDataCard(null)
                        }}
                    />
                    <BtnItem
                        addClass=""
                        contents="카드 등록"
                        disabled={cardBtnChk}
                        func={() => {
                            cardAdd();
                        }}
                    />
                </BtnBox>
            </CustomPopup>
            <Popup
                data={popupData}
            />
        </>
    );
};

export default MarketOrder;