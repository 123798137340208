import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { ProductItem, ProductListBox, ProductListBoxMin, SubProductList, SubProductListSlide } from "component/product/items";
import { NotItems } from "component/basic/notItems";
import { DataBtn } from "component/basic";
import { MarketMainBannerControll, MarketMainBtnBox, MarketMainSection, MarketMainTap, MarketSectionTitleArea, MarketSectionTitleBox, MarketSubBanner } from "component/market/items";
import { LoadingBox } from "component/basic/popup";

const Market = (props) => {
    const navigate = useNavigate();

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(true);

    const [tap,setTap] =  useState(null);

    const mainApi = useGet({
        url:`/product/home?user_family_id=${sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : ""}&category_id=${tap}`,
        loginType:"login"
    });
    const mainApiData = mainApi?.data;
    const banners = mainApiData?.banners;
    const user_name = mainApiData?.user_name;
    const ai_products = mainApiData?.ai_products;
    const hot_deal_products = mainApiData?.hot_deal_products;

    const categoryApi = useGet({
        url:`/product/category`,
        loginType:"login"
    });
    const categoryApiData = categoryApi?.data?.product_categories;

    const productApi = useGet({
        url:`/product?page=1&product_category_id=${tap}&search&order=order&limit=8`,
        loginType:"login"
    });
    const productApiData = productApi?.data?.products;

    useEffect(() => {
        if(categoryApiData && categoryApiData[0]){
            setTap(categoryApiData[0]?.id||null);
        }
    }, [categoryApi]);

    useEffect(() => {
        setLoadingBoxOpen(true);
    }, []);

    useEffect(() => {
        if(mainApiData){
            setLoadingBoxOpen(false);
        }

        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 10000);
    }, [mainApi]);

    useEffect(() => {
        const scriptItem = document.createElement("script");
        scriptItem.id = "vreviewScript";
        scriptItem.src = `https://script.vreview.tv/vreview.widget.js?vrid=${process.env.REACT_APP_VREVIEW_ID}`;
        document.head.appendChild(scriptItem);
        
        return () => {
            if(scriptItem){
                scriptItem.remove();
            }
        };
    }, []);

    useEffect(() => {
        if(document.querySelector("#vreviewScript")){
            document.querySelector("#vreviewScript").remove();
        }

        const scriptItem = document.createElement("script");
        scriptItem.id = "vreviewScript";
        scriptItem.src = `https://script.vreview.tv/vreview.widget.js?vrid=${process.env.REACT_APP_VREVIEW_ID}`;
        document.head.appendChild(scriptItem);
        
        return () => {
            if(scriptItem){
                scriptItem.remove();
            }
        };
    }, [mainApi,productApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <div className="mainPage">
                    {banners ? 
                        <div className="marketMainBanner">
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={3}
                                centeredSlides={true}
                                autoHeight={true}
                                pagination={true}
                                loop={true}
                                modules={[Pagination]}
                            >
                                {banners && banners?.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <a href={item.url} target="_blank" style={{"backgroundImage":`url('${item.image_url}')`}} className="backgroundImg marketMainBannerImg"/>
                                    </SwiperSlide>
                                ))}
                                <MarketMainBannerControll/>
                            </Swiper>
                        </div>
                    :""}
                    {localStorage.getItem("token") ? 
                    <MarketMainSection>
                        <MarketSectionTitleBox
                            title={`${user_name||"고객"} 님을 위한 <span class='oTextColor'>AI 맞춤 상품</span>`}
                            subTitle={`지금 ${user_name||"고객"}님의 건강 상태에 적합한 식단을 골라봤어요.`}
                        />
                        <ProductListBox>
                            {ai_products && ai_products?.length > 0 ? 
                                <>
                                    {ai_products && ai_products?.map((item, i) => (
                                        <ProductItem
                                            key={i}
                                            type="market"
                                            id={item.id}
                                            image_url={item.main_image_url}
                                            product_name={item.name}
                                            summary={item.summary}
                                            price={item.price}
                                            discount_price={item.discount_price}
                                        />
                                    ))}
                                </>
                            :
                                <NotItems text={`상품 목록이 없습니다.`}/>
                            }
                        </ProductListBox>
                        <MarketMainBtnBox>
                            <DataBtn func={()=>{navigate(`/market/list`)}}>상품 더 알아보기</DataBtn>
                        </MarketMainBtnBox>
                    </MarketMainSection>
                    :""}
                    <MarketMainSection>
                        <MarketSubBanner
                            data={[
                                {
                                    id:1,
                                    bgImg:"/assets/images/img/market_subBanner_bg_0.png",
                                    title:"맛있저염 식단에 대한 설명 타이틀",
                                    text:"맛있저염이 어떤 브랜드인지, 어떤 고객을 대상으로 식단을 제공하는지 설명합니다.",
                                    logo:"market_subBanner_logo_0"
                                },
                                {
                                    id:2,
                                    bgImg:"/assets/images/img/market_subBanner_bg_1.png",
                                    title:"맛있저당 식단에 대한 설명 타이틀",
                                    text:"맛있저염이 어떤 브랜드인지, 어떤 고객을 대상으로 식단을 제공하는지 설명합니다.",
                                    logo:"market_subBanner_logo_1"
                                },
                                {
                                    id:3,
                                    bgImg:"/assets/images/img/market_subBanner_bg_2.png",
                                    title:"맛있저요 식단에 대한 설명 타이틀",
                                    text:"맛있저염이 어떤 브랜드인지, 어떤 고객을 대상으로 식단을 제공하는지 설명합니다.",
                                    logo:"market_subBanner_logo_2"
                                },
                                {
                                    id:4,
                                    bgImg:"/assets/images/img/market_subBanner_bg_3.png",
                                    title:"맛있고영 식단에 대한 설명 타이틀",
                                    text:"맛있저염이 어떤 브랜드인지, 어떤 고객을 대상으로 식단을 제공하는지 설명합니다.",
                                    logo:"market_subBanner_logo_3"
                                }
                            ]}
                        />
                    </MarketMainSection>
                    <MarketMainSection addClass="gBg" bgChk={true}>
                        <SubProductList
                            addClass={hot_deal_products && hot_deal_products?.length > 0 ? "" : "subProductList_notItem"}
                            title="🔥 HOT DEAL<br/><span class='oTextColor'>높은 할인 식단</span>"
                            text={`현재 시간을 기준으로<br/>할인율이 높은 식단들을 골라봤어요!`}
                            allBtn={true}
                            func={()=>{navigate("/market/list")}}
                            allBtnText={null}
                            btnAddClass=""
                            itemBoxAddClass="basicList"
                        >
                            {hot_deal_products && hot_deal_products?.length > 0 ? 
                                <ProductListBoxMin addClass="pcContents">
                                    {hot_deal_products && hot_deal_products?.map((item, i) => (
                                        <ProductItem
                                            key={i}
                                            type="market"
                                            id={item.id}
                                            image_url={item.main_image_url}
                                            product_name={item.name}
                                            summary={item.summary}
                                            price={item.price}
                                            discount_price={item.discount_price}
                                        />
                                    ))}
                                </ProductListBoxMin>
                            :
                                <NotItems text={`상품 목록이 없습니다.`}/>
                            }
                            <SubProductListSlide addClass="mContents" data={hot_deal_products} type="market"/>
                        </SubProductList> 
                    </MarketMainSection>
                    <MarketMainSection>
                        <MarketSectionTitleArea
                            title={`목적 별 <span class='oTextColor'>맞춤 상품</span>`}
                            subTitle={`저당, 저염 식단을 찾고계신가요? 맛저마켓에서 원하는 식단을 판매하고 있어요.`}
                        >
                            <MarketMainTap
                                tapFunc={(id)=>{setTap(id)}}
                                tapVal={tap}
                                tap={[
                                    {
                                      "id": 1,
                                      "name": "<img src='/assets/images/img/market_subTap_logo_0.svg' class='pcContents'/><span class='mContents'>맛있저염</span>"
                                    },
                                    {
                                      "id": 2,
                                      "name": "<img src='/assets/images/img/market_subTap_logo_1.svg' class='pcContents'/><span class='mContents'>맛있저당</span>"
                                    },
                                    {
                                      "id": 3,
                                      "name": "<img src='/assets/images/img/market_subTap_logo_2.svg' class='pcContents'/><span class='mContents'>맛있저요</span>"
                                    },
                                    {
                                      "id": 4,
                                      "name": "<img src='/assets/images/img/market_subTap_logo_3.svg' class='pcContents'/><span class='mContents'>맛있고영</span>"
                                    }
                                ]}
                            />
                        </MarketSectionTitleArea>
                        <ProductListBox>
                            {productApiData && productApiData?.length > 0 ? 
                                <>
                                    {productApiData && productApiData?.map((item, i) => (
                                        <ProductItem
                                            key={i}
                                            type="market"
                                            id={item.id}
                                            image_url={item.main_image_url}
                                            product_name={item.name}
                                            summary={item.summary}
                                            price={item.price}
                                            discount_price={item.discount_price}
                                        />
                                    ))}
                                </>
                            :
                                <NotItems text={`상품 목록이 없습니다.`}/>
                            }
                        </ProductListBox>
                        <MarketMainBtnBox>
                            <DataBtn func={()=>{navigate(`/market/list`)}}>상품 더 알아보기</DataBtn>
                        </MarketMainBtnBox>
                    </MarketMainSection>
                    <MarketMainSection>
                        <div className="vreview-collection-widget"></div>
                    </MarketMainSection>
                </div>   
            </ContentsSection>
            <Footer/>
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default Market;