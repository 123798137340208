import useGet from "api/useGet";
import { ChannelShowMessenger } from "component/app/channelTalk";
import { bodyScroll_clear, bodyScroll_limit } from "js/function";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function PcheaderSubMenuArea(data){
    return (
        <div className="pcheader_subMenu_area">
            {data.children}
        </div>
    );
}

function PcheaderSubMenuItem(data){
    const { pathname } = useLocation();

    return (
        <button type="button" onClick={()=>{data.func()}} className={`pcheader_subMenu_item ${data.pathname && pathname === data.pathname ? "active" : ""} ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function HeaderLoginLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`header_loginLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function HeaderSubLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`header_subLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
        </button>
    );
}

function HeaderLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`header_link ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function SideMenuLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`sideMenu_link ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function SideMenuSubLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`sideMenu_subLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
        </button>
    );
}

{/*
<Header
    subLinkType="market"//care
    addClass=""
    mMenu={true}//false
    logo={true}//false
    pageBack={true}//false
    func={()=>{}}
    headTitle=""
    rightBtnsArr={[
        {
            addClass:"",
            func:()=>{},
            contents:""
        },
        {
            addClass:"",
            func:()=>{},
            contents:""
        }
    ]}//null
    closeFunc={()=>{}}
    pageClose={true}//true
/>
*/}

function Header(headerData){
    let navigate = useNavigate();
    const [subBanner,setSubBanner] = useState(false);
    const [pcSubMenu,setPcSubMenu] = useState(false);
    const [menuOpen,setMenuOpen] = useState(false);

    const headerApi = useGet({
        url:`/header?user_family_id=${sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : ""}`,
        loginType:"login"
    });
    const headerApiData = headerApi?.data;

    function closePopup(){
        bodyScroll_clear();
        setMenuOpen(false);
    }
    
    function subBannerClose(){
        setSubBanner(false)
        sessionStorage.setItem("headerSubBanner",false)
        window.dispatchEvent(new Event("storage"))
    }

    useEffect(() => {
        if(menuOpen){
            bodyScroll_limit();
        }else{
            bodyScroll_clear();
        }
    }, [menuOpen]);

    useEffect(() => {
        if(headerApiData && headerApiData?.header_banner){
                setSubBanner(true)
                sessionStorage.setItem("headerSubBanner",true)
                window.dispatchEvent(new Event("storage"))
            }else{
                subBannerClose();
            }
    }, [headerApi]);

    return (
        <div className="header">
            <div className="pcheader">
                <div className="header_subBanner" style={subBanner ? {"display":"block"} : {"display":"none"}}>
                    {headerApiData?.header_banner ? 
                        <a href={headerApiData?.header_banner?.url} target="_blank" style={headerApiData?.header_banner?.color ? {backgroundColor:headerApiData?.header_banner?.color} : {}} className="header_subBanner_item">
                            <span style={headerApiData?.header_banner?.color ? {color:headerApiData?.header_banner?.color} : {}}>{headerApiData?.header_banner?.badge_text}</span>
                            {headerApiData?.header_banner?.main_text}
                            <img src="/assets/images/icon/move_w.svg"/>
                        </a>
                    :""}
                    <button type="button" onClick={()=>{subBannerClose()}} className="header_subBanner_close">
                        <img src="/assets/images/icon/close.svg"/>
                    </button>
                </div>
                <div className="header_loginLinkArea">
                    <div className="header_loginLinkBox">
                        {localStorage.getItem("token") ? 
                            <>
                                <HeaderLoginLink
                                    contents="로그아웃"
                                    func={()=>{window.logOut()}}
                                />
                                <HeaderLoginLink
                                    contents="마이페이지"
                                    func={()=>{navigate("/mypage/order")}}
                                />
                            </>    
                            :
                            <>
                                <HeaderLoginLink
                                    contents="로그인"
                                    func={()=>{
                                        let nowUrl = new URL(window.location.href);
                                        sessionStorage.setItem("setUrl",nowUrl.pathname);
                                        navigate("/welCome")
                                    }}
                                />
                                <HeaderLoginLink
                                    contents="아이디/비밀번호 찾기"
                                    func={()=>{navigate("/member/login")}}
                                />
                            </>
                        }
                        <HeaderLoginLink
                            contents="고객센터"
                            func={()=>{
                                //채널톡 오픈
                                ChannelShowMessenger();
                            }}
                        />
                    </div>
                </div>
                <div className="headerSection">
                    <div className="headerArea headerArea_left">
                        <button type="button" onClick={()=>{navigate("/market")}} className="headerLogo">
                            <img src="/assets/images/img/logo.svg"/>
                        </button>
                        <div className="header_subLinkBox">
                            <HeaderSubLink
                                addClass={headerData.subLinkType === "market" ? "active" : ""}
                                func={()=>{navigate("/market")}}
                            >
                                <img src={`/assets/images/icon/header_menu_0_${headerData.subLinkType === "market" ? "on" : "off"}.svg`}/>
                                맛저마켓
                            </HeaderSubLink>
                            <HeaderSubLink
                                addClass={headerData.subLinkType === "care" ? "active" : ""}
                                func={()=>{navigate("/")}}
                            >
                                <img src={`/assets/images/icon/header_menu_1_${headerData.subLinkType === "care" ? "on" : "off"}.svg`}/>
                                케어센터
                            </HeaderSubLink>
                        </div>
                    </div>
                    <div className="headerArea headerArea_right">
                        {headerData.subLinkType === "market" ? 
                        <>
                            <HeaderLink
                                contents="맛저마켓 홈"
                                func={()=>{navigate("/market")}}
                            />
                            <HeaderLink
                                contents="식단 구매하기"
                                func={()=>{navigate("/market/list")}}
                            />
                            <HeaderLink
                                contents="레시피 찾기"
                                func={()=>{navigate("/recipe")}}
                            />
                            <HeaderLink
                                contents="영양 상담"
                                func={()=>{navigate("/nutrition/survey")}}
                            />
                        </>   
                        :
                        <>
                            <HeaderLink
                                contents="케어홈"
                                func={()=>{navigate("/")}}
                            />
                            <HeaderLink
                                contents="건강 대시보드"
                                func={()=>{
                                    if(localStorage.getItem("token")){
                                        if(headerApiData?.is_health_info){
                                            //건강데이터 있음(결과수치 확인)
                                            navigate(`/healthSurvey/detail`)
                                        }else{
                                            //건강데이터 등록
                                            navigate(`/healthSurvey`)
                                        }
                                    }else{
                                        let nowUrl = new URL(window.location.href);
                                        sessionStorage.setItem("setUrl",nowUrl.pathname);
                                        navigate("/welcome")
                                    }
                                }}
                            />
                            <HeaderLink
                                contents="건강 데이터 등록"
                                func={()=>{
                                    if(localStorage.getItem("token")){
                                        navigate(`/healthSurvey`);
                                    }else{
                                        let nowUrl = new URL(window.location.href);
                                        sessionStorage.setItem("setUrl",nowUrl.pathname);
                                        navigate("/welcome")
                                    }
                                }}
                            />
                            <HeaderLink
                                contents="고객센터"
                                func={()=>{
                                    //채널톡 오픈
                                    ChannelShowMessenger();
                                }}
                            />
                        </>}
                        <button type="button" onClick={()=>{
                            if(localStorage.getItem("token")){
                                setPcSubMenu(!pcSubMenu)
                            }else{
                                let nowUrl = new URL(window.location.href);
                                sessionStorage.setItem("setUrl",nowUrl.pathname);
                                navigate("/welCome")
                            }
                        }} className={`header_subMenu_openBtn ${pcSubMenu ? "active" : ""}`}>
                            <img src="/assets/images/icon/user_icon.svg"/>
                            <p className="header_userName">
                                {localStorage.getItem("token") ? 
                                    headerApiData?.name ? headerApiData?.name : "고객님"
                                :
                                    "로그인"
                                }
                            </p>
                            {localStorage.getItem("token") ? 
                                <>
                                <img src="/assets/images/icon/user_menu_icon.svg" className="header_subMenu_openBtn_icon"/>   
                                <span className="header_cartCount">{headerApiData?.cart_count ? headerApiData?.cart_count : 0}</span>
                                </>
                                :
                                ""
                            }
                        </button>
                    </div>
                    <div className={`pcheader_subMenu ${pcSubMenu ? "active" : ""}`}>
                        <PcheaderSubMenuArea>
                            <PcheaderSubMenuItem
                                pathname="/mypage/order"
                                contents="주문 내역"
                                func={()=>{navigate("/mypage/order");setPcSubMenu(false)}}
                            />
                            <PcheaderSubMenuItem
                                pathname="/mypage/review"
                                contents="나의 리뷰"
                                func={()=>{navigate("/mypage/review");setPcSubMenu(false)}}
                            />
                            <PcheaderSubMenuItem
                                pathname="/mypage/recipe"
                                contents="나의 레시피"
                                func={()=>{navigate("/mypage/recipe");setPcSubMenu(false)}}
                            />
                            <PcheaderSubMenuItem
                                pathname="/order/cart"
                                contents="장바구니"
                                func={()=>{navigate("/order/cart");setPcSubMenu(false)}}
                            />
                        </PcheaderSubMenuArea>
                        <PcheaderSubMenuArea>
                            <PcheaderSubMenuItem
                                pathname="/mypage/editProfile"
                                contents="프로필 수정"
                                func={()=>{navigate("/mypage/editProfile");setPcSubMenu(false)}}
                            />
                            <PcheaderSubMenuItem
                                pathname="/mypage/family"
                                contents="가족 프로필 관리"
                                func={()=>{navigate("/mypage/family");setPcSubMenu(false)}}
                            />
                            <PcheaderSubMenuItem
                                pathname="/mypage/ingredient"
                                contents="선호/기피 재료 설정"
                                func={()=>{navigate("/mypage/ingredient");setPcSubMenu(false)}}
                            />
                            <PcheaderSubMenuItem
                                pathname="/mypage/menu"
                                contents="식단표"
                                func={()=>{navigate("/mypage/menu");setPcSubMenu(false)}}
                            />
                            </PcheaderSubMenuArea>
                        <PcheaderSubMenuArea>
                            <PcheaderSubMenuItem
                                pathname={null}
                                contents="로그아웃"
                                func={()=>{window.logOut();setPcSubMenu(false)}}
                            />
                        </PcheaderSubMenuArea>
                    </div>
                </div>        
            </div>
            <div className="mheader">
                <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                    {headerData.mMenu ? 
                        <button type="button" onClick={()=>setMenuOpen(true)} className={`btnHeaderLeft btnMenuOpen ${menuOpen ? "active" : ""}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    :""}
                    {!headerData.pageBack || headerData.pageBack === "" ? "" : <button type="button" className="btnBack btnHeaderLeft" onClick={(e) => headerData.func(e)}><img src="/assets/images/basic/back.svg"/></button>}
                    {headerData.logo ? <button type="button" className="btnLogo" onClick={(e) => navigate("/market")}><img src="/assets/images/img/logo.svg"/></button> : ""}
                    {headerData.headTitle ? <p className={`headTitle ${headerData.centerTitle ? "centerTitle" : ""} ${headerData.titleClass ? headerData.titleClass : ""}`} dangerouslySetInnerHTML={{__html:headerData.headTitle}}/> : ""}
                    {headerData.rightBtnsArr ? 
                        <div className="headerInfoBox btnHeaderRight">
                            {headerData.rightBtnsArr.map((item, i) => (
                                <Fragment key={i}>
                                    {item.type !== "img" ?
                                    <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}} dangerouslySetInnerHTML={{__html:item.contents}}></button>
                                    :
                                    <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}}><img src={item.contents}/></button>
                                    }
                                </Fragment>
                            ))}
                        </div>
                        : ""
                    }
                    {headerData.pageClose ? <button type="button" className="btnClose btnHeaderRight" onClick={(e) => {headerData.closeFunc(e)}}><img src="/assets/images/basic/close.svg"/></button> : ""}
                    {headerData.children}
                </div>
                <div className={`sideCover ${menuOpen ? "active" : ""}`} onClick={()=>{closePopup()}}></div>
                <div className={`sideMenu ${menuOpen ? "active" : ""}`}>
                    <div className="sideMenu_section">
                        {headerApiData?.header_banner ? 
                            <a href={headerApiData?.header_banner?.url} style={headerApiData?.header_banner?.color ? {backgroundColor:headerApiData?.header_banner?.color} : {}} onClick={()=>{closePopup()}} target="_blank" className="sideMenu_subBanner">
                                {headerApiData?.header_banner?.main_text}
                                <img src="/assets/images/icon/move_w.svg"/>
                            </a>
                        :""}
                        <button type="button" onClick={()=>{
                            if(localStorage.getItem("token")){
                                navigate("/mypage/order")
                            }else{
                                let nowUrl = new URL(window.location.href);
                                sessionStorage.setItem("setUrl",nowUrl.pathname);
                                navigate("/welCome")
                            }
                            closePopup()
                        }}  className="sideMenu_userInfoBox">
                            <h1 className="sideMenu_userName">
                                {localStorage.getItem("token") ? `${headerApiData?.name ? headerApiData?.name : "고객"} 님` : "로그인"}
                            </h1>
                            <img src="/assets/images/icon/user_icon.svg"/>
                        </button>
                        <div className="sideMenu_area">
                            {headerData.subLinkType === "market" ? 
                            <>
                                <SideMenuLink
                                    contents="맛저마켓 홈"
                                    func={()=>{navigate("/market");closePopup()}}
                                />
                                <SideMenuLink
                                    contents="식단 구매하기"
                                    func={()=>{navigate("/market/list");closePopup()}}
                                />
                                <SideMenuLink
                                    contents="레시피 찾기"
                                    func={()=>{navigate("/recipe");closePopup()}}
                                />
                                <SideMenuLink
                                    contents="영양 상담"
                                    func={()=>{navigate("/nutrition/survey");closePopup()}}
                                />
                                <SideMenuLink
                                    contents="장바구니"
                                    func={()=>{navigate("/order/cart");closePopup()}}
                                />
                            </>   
                            :
                            <>
                                <SideMenuLink
                                    contents="케어홈"
                                    func={()=>{navigate("/");closePopup()}}
                                />
                                <SideMenuLink
                                    contents="건강 대시보드"
                                    func={()=>{
                                        if(localStorage.getItem("token")){
                                            if(headerApiData?.is_health_info){
                                                //건강데이터 있음(결과수치 확인)
                                                navigate(`/healthSurvey/detail`)
                                            }else{
                                                //건강데이터 등록
                                                navigate(`/healthSurvey`)
                                            }
                                        }else{
                                            let nowUrl = new URL(window.location.href);
                                            sessionStorage.setItem("setUrl",nowUrl.pathname);
                                            navigate("/welcome")
                                        }

                                        closePopup()
                                    }}
                                />
                                <SideMenuLink
                                    contents="건강 데이터 등록"
                                    func={()=>{
                                        if(localStorage.getItem("token")){
                                            navigate(`/healthSurvey`);
                                        }else{
                                            let nowUrl = new URL(window.location.href);
                                            sessionStorage.setItem("setUrl",nowUrl.pathname);
                                            navigate("/welcome")
                                        }
                                        closePopup()
                                    }}
                                />
                                <SideMenuLink
                                    contents="고객센터"
                                    func={()=>{
                                        //채널톡 오픈
                                        ChannelShowMessenger();
                                        closePopup()
                                    }}
                                />
                            </>}
                        </div>
                    </div>
                    <div className="sideMenu_subSection">
                        <SideMenuSubLink
                            addClass={headerData.subLinkType === "market" ? "active" : ""}
                            func={()=>{navigate("/market");closePopup()}}
                        >
                            <img src={`/assets/images/icon/header_menu_0_${headerData.subLinkType === "market" ? "on" : "off"}.svg`}/>
                            맛저마켓
                        </SideMenuSubLink>
                        <SideMenuSubLink
                            addClass={headerData.subLinkType === "care" ? "active" : ""}
                            func={()=>{navigate("/");closePopup()}}
                        >
                            <img src={`/assets/images/icon/header_menu_1_${headerData.subLinkType === "care" ? "on" : "off"}.svg`}/>
                            케어센터
                        </SideMenuSubLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {Header};