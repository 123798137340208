import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { ageSet, bmiChk, nutritionChk } from "js/function";
import { BtnBox, BtnItem } from "component/basic/btns";
import { SurveyDetailConsulting, SurveyDetailNutrition, SurveyDetailSection, SurveyDetailTitleBox, SurveyDetailUserInfo } from "component/survey/items";
import { DataBtn } from "component/basic";
import { ProductDetailInfo } from "component/product/detail";
import { ChannelShowMessenger } from "component/app/channelTalk";
import { LoadingBox } from "component/basic/popup";

const NutritionSurveyDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(true);

    const detailApi = useGet({
        url:`/consult/nutrition/result?nutrition_consult_id=${id}`,
        loginType:"login"
    });
    const detailApiData = detailApi?.data;
    const nutrition_consult = detailApiData?.nutrition_consult;
    const recommend_product = detailApiData?.recommend_product;
    const nutrition_consult_vegetable = detailApiData?.nutrition_consult_vegetable;
    const nutrition_config = detailApiData?.nutrition_config;
    const nutrition_consult_questions = detailApiData?.nutrition_consult_questions;
   
    useEffect(() => {
        setLoadingBoxOpen(true);
    }, []);

    useEffect(() => {
        if(detailApiData){
            setLoadingBoxOpen(false);
        }

        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 10000);
    }, [detailApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn_r2={true}
                pcFooter={true}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <SurveyDetailSection addClass="surveyDetail_flexSection">
                    <SurveyDetailTitleBox
                        title={`<span class='oTextColor'>${nutrition_consult?.name}</span> 님의 건강 데이터`}
                        subTitle={`영양상담일 ${nutrition_consult?.date}`}
                    />
                    <SurveyDetailUserInfo
                        data={[
                            {
                                name:"키",
                                val:`${nutrition_consult?.height||"-"} cm`
                            },
                            {
                                name:"체중",
                                val:`${nutrition_consult?.weight||"-"} kg`
                            },
                            {
                                name:"나이",
                                val:`${ageSet(nutrition_consult?.date,nutrition_consult?.birth)||"-"} 세`
                            },
                            {
                                name:"비만도",
                                val:`${nutrition_consult?.bmi ? bmiChk(nutrition_consult?.bmi) : "-"}`
                            },
                            {
                                name:"사구체여과율",
                                val:`${nutrition_consult?.gfr_value||"-"}%`
                            },
                            {
                                name:"크레아티닌 수치",
                                val:`${nutrition_consult?.cr_value||"-"}mg/dL`
                            },
                            {
                                name:"공복혈당",
                                val:`${nutrition_consult?.fasting_blood_sugar||"-"}mg/dL`
                            },
                            {
                                name:"당화혈색소",
                                val:`${nutrition_consult?.glycated_hemoglobin||"-"}%`
                            },
                            {
                                name:"주요 관리질환",
                                val:`${nutrition_consult?.disease||"-"}`
                            },
                            {
                                name:"보유질환",
                                val:`${nutrition_consult?.have_disease||"-"}`
                            },
                            {
                                name:"현재 복용중인 약",
                                val:`${nutrition_consult?.drug||"-"}`
                            }
                        ]}
                    />
                </SurveyDetailSection>
                {recommend_product?.id ? 
                    <SurveyDetailSection addClass="">
                        <SurveyDetailTitleBox
                            title={`추천 식단`}
                            subTitle={`고객님에게 추천하는 식단을 아래와 같이 제안드릴게요.`}
                        />
                        <div className="surveyDetail_productDetailArea">
                            <ProductDetailInfo
                                addClass=""
                                detailType="marketSub"
                                id={recommend_product?.id}
                                image_url={recommend_product?.main_image_url}
                                category_name={recommend_product?.category}
                                likeFunc={null}
                                sharingFunc={null}
                                name={recommend_product?.name}
                                summary={recommend_product?.summary}
                                price={recommend_product?.price}
                                discount_price={recommend_product?.discount_price}

                                like={recommend_product?.my_like}
                            />
                        </div>
                    </SurveyDetailSection>
                :""}
                <SurveyDetailSection addClass="">
                    <SurveyDetailTitleBox
                        title={`식사 섭취 가이드`}
                        subTitle={`고객님의 건강 상태에 따라 주의해야되는 식사요법에 대해 안내드려요.`}
                    />
                    <EditerBox
                        addClass="surveyDetail_meal_limit" data={nutrition_consult?.meal_limit}
                    />
                </SurveyDetailSection>
                <SurveyDetailSection addClass="">
                    <SurveyDetailTitleBox
                        title={`하루 영양구성 분석 결과`}
                        subTitle={`고객님의 하루 영양구성을 분석한 결과, 다음과 같은 영양구성을 제안드려요.`}
                    />
                    <SurveyDetailNutrition
                        data={[
                            {
                                color: nutritionChk(nutrition_config?.calorie_min,nutrition_config?.calorie_max,nutrition_consult_vegetable?.enerc,"color"),
                                colorChk:true,
                                type: nutritionChk(nutrition_config?.calorie_min,nutrition_config?.calorie_max,nutrition_consult_vegetable?.enerc,"type"),
                                unit:"kcal",
                                val:nutrition_consult_vegetable?.enerc,
                                name:"칼로리"
                            },
                            {
                                color:nutritionChk(nutrition_config?.natrium_min,nutrition_config?.natrium_max,nutrition_consult_vegetable?.nat,"color"),
                                colorChk:true,
                                type:nutritionChk(nutrition_config?.natrium_min,nutrition_config?.natrium_max,nutrition_consult_vegetable?.nat,"type"),
                                unit:"mg",
                                val:nutrition_consult_vegetable?.nat,
                                name:"나트륨"
                            },
                            {
                                color:nutritionChk(nutrition_config?.carbohydrate_min,nutrition_config?.carbohydrate_max,nutrition_consult_vegetable?.chocdf,"color"),
                                colorChk:true,
                                type:nutritionChk(nutrition_config?.carbohydrate_min,nutrition_config?.carbohydrate_max,nutrition_consult_vegetable?.chocdf,"type"),
                                unit:"g",
                                val:nutrition_consult_vegetable?.chocdf,
                                name:"탄수화물"
                            },
                            {
                                color:nutritionChk(nutrition_config?.sugar_min,nutrition_config?.sugar_max,nutrition_consult_vegetable?.sugar,"color"),
                                colorChk:true,
                                type:nutritionChk(nutrition_config?.sugar_min,nutrition_config?.sugar_max,nutrition_consult_vegetable?.sugar,"type"),
                                unit:"g",
                                val:nutrition_consult_vegetable?.sugar,
                                name:"당류"
                            },
                            {
                                color:nutritionChk(nutrition_config?.fat_min,nutrition_config?.fat_max,nutrition_consult_vegetable?.fatce,"color"),
                                colorChk:true,
                                type:nutritionChk(nutrition_config?.fat_min,nutrition_config?.fat_max,nutrition_consult_vegetable?.fatce,"type"),
                                unit:"g",
                                val:nutrition_consult_vegetable?.fatce,
                                name:"지방"
                            },
                            {
                                color:nutritionChk(nutrition_config?.saturated_fat_min,nutrition_config?.saturated_fat_max,nutrition_consult_vegetable?.fasat,"color"),
                                colorChk:true,
                                type:nutritionChk(nutrition_config?.saturated_fat_min,nutrition_config?.saturated_fat_max,nutrition_consult_vegetable?.fasat,"type"),
                                unit:"g",
                                val:nutrition_consult_vegetable?.fasat,
                                name:"포화지방"
                            },
                            {
                                color:nutritionChk(nutrition_config?.protein_min,nutrition_config?.protein_max,nutrition_consult_vegetable?.prot,"color"),
                                colorChk:true,
                                type:nutritionChk(nutrition_config?.protein_min,nutrition_config?.protein_max,nutrition_consult_vegetable?.prot,"type"),
                                unit:"g",
                                val:nutrition_consult_vegetable?.prot,
                                name:"단백질"
                            }
                        ]}
                    />
                </SurveyDetailSection>
                <SurveyDetailSection addClass="">
                    <SurveyDetailTitleBox
                        title={`영양 컨설팅`}
                        subTitle={`고객님과의 상담 과정 중에서 꼭 알고계셔야 하는 내용에 대해 답변드려요.`}
                    />
                    {nutrition_consult_questions && nutrition_consult_questions?.length > 0 ? 
                        <SurveyDetailConsulting data={nutrition_consult_questions}/>
                    :""}
                    <div className="surveyDetail_btnBox">
                        <DataBtn func={()=>{
                            //채널톡 오픈
                            ChannelShowMessenger();
                        }}>더 궁금한 내용이 있다면 <span className="oTextColor">채널톡</span> 문의하기</DataBtn>
                        <BtnBox
                            boxType="fixed"
                            addClass="pcBasicBtnBox col2 mRow2"
                        >
                            <BtnItem
                                addClass=""
                                contents={"추천 식단 구매하기"}
                                disabled={recommend_product?.id ? false : true}
                                func={() => {navigate(`/market/detail/${recommend_product?.id}`)}}
                            />
                            <BtnItem
                                addClass="line"
                                contents={"뒤로"}
                                disabled={false}
                                func={() => {navigate(-1)}}
                            />
                        </BtnBox>
                    </div>
                </SurveyDetailSection>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default NutritionSurveyDetail;