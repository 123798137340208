import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, InputContents } from "component/app/items";
import { Header } from "component/elements/header";
import { TermsContents, TermsContentsBox, TermsTitle } from "component/terms";
import { bottomChkEl, setDateTime } from "js/function";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Swiper, SwiperSlide } from "swiper/react";
import { CustomSelect, DatePicer, InputBoxCol2, InputBoxOnly, InputItemBox, InputTap } from "component/basic/formItems";
import { SurveyInfo, SurveyListTr, SurveySelect, SurveySelectSelItem, SurveyTypeBtn } from "component/survey/items";
import { TableSection } from "component/basic";
import { Popup } from "component/basic/popup";
import { ChannelShowMessenger } from "component/app/channelTalk";

const NutritionSurvey = (props) => {
    const navigate = useNavigate();
    const type = sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : "my";

    const [submitChk,setSubmitChk] =  useState(true);

    const [page,setPage] =  useState(0);
    const [btnChk,setBtnChk] =  useState(true);
    const [popupData,setPopupData] =  useState(null);

    //page2
    const [disease,setDisease] =  useState(null);

    //page3
    const [consultType,setConsultType] =  useState(null);

    //page4
    const [gender,setGender] =  useState(null);
    const [birthday,setBirthday] =  useState(null);
    const [userHeight,setUserHeight] =  useState(null);
    const [userWeight,setUserWeight] =  useState(null);
    const [diagnosis_menu,setDiagnosis_menu] =  useState(null);

    //신장
    const [kidney_status,setKidney_status] =  useState(null);
    
    const [cr_value,setCr_value] =  useState(null);
    const [gfr_value,setGfr_value] =  useState(null);

    //당뇨
    const [diabetes_type,setDiabetes_type] =  useState(null);
    const [fasting_blood_sugar,setFasting_blood_sugar] =  useState(null);
    const [after_meal_blood_sugar,setAfter_meal_blood_sugar] =  useState(null);
    const [glycated_hemoglobin,setGlycated_hemoglobin] =  useState(null);

    //암
    const [before_6month_change_weight,setBefore_6month_change_weight] =  useState(null);
    const [edema,setEdema] =  useState(null);
    const [loss_of_appetite,setLoss_of_appetite] =  useState(null);
    const [cancer_type,setCancer_type] =  useState(null);
    const [need_manage_disease,setNeed_manage_disease] =  useState([]);
    const [albumin_value,setAlbumin_value] =  useState(null);
    const [hb_value,setHb_value] =  useState(null);

    //page5
    const [hard_dietotherapy,setHard_dietotherapy] =  useState(null);
    
    const surveyApi = useGet({
        url:`/consult/nutrition`,
        loginType:"login"
    });
    const nutrition_consult_contents = surveyApi?.data?.nutrition_consult_contents;
    const nutrition_consults = surveyApi?.data?.nutrition_consults;

    //page 6
    const [morningSel,setMorningSel] =  useState([]);
    const [morningList,setMorningList] =  useState([]);

    const [morningKey,setMorningKey] =  useState(null);
    const [morningKeyApi,setMorningKeyApi] =  useState(null);
    const [morningPage,setMorningPage] =  useState(1);

    const morningApi = useGet({
        url: morningKeyApi ? `/ingredient?keyword=${morningKeyApi}&page=${morningPage}` : null,
        loginType:"login"
    });
    const morningApiData = morningApi?.data?.ingredients;

    const [morningLastPage,setMorningLastPage] =  useState(false);
    const [morningSettingChk,setMorningSettingChk] =  useState(0);

    //page 7
    const [afternoonSel,setAfternoonSel] =  useState([]);
    const [afternoonList,setAfternoonList] =  useState([]);

    const [afternoonKey,setAfternoonKey] =  useState(null);
    const [afternoonKeyApi,setAfternoonKeyApi] =  useState(null);
    const [afternoonPage,setAfternoonPage] =  useState(1);
    
    const afternoonApi = useGet({
        url: afternoonKeyApi ? `/ingredient?keyword=${afternoonKeyApi}&page=${afternoonPage}` : null,
        loginType:"login"
    });
    const afternoonApiData = afternoonApi?.data?.ingredients;

    const [afternoonLastPage,setAfternoonLastPage] =  useState(false);
    const [afternoonSettingChk,setAfternoonSettingChk] =  useState(0);

    //page 8
    const [eveningSel,setEveningSel] =  useState([]);
    const [eveningList,setEveningList] =  useState([]);

    const [eveningKey,setEveningKey] =  useState(null);
    const [eveningKeyApi,setEveningKeyApi] =  useState(null);
    const [eveningPage,setEveningPage] =  useState(1);

    const eveningApi = useGet({
        url: eveningKeyApi ? `/ingredient?keyword=${eveningKeyApi}&page=${eveningPage}` : null,
        loginType:"login"
    });
    const eveningApiData = eveningApi?.data?.ingredients;

    const [eveningLastPage,setEveningLastPage] =  useState(false);
    const [eveningSettingChk,setEveningSettingChk] =  useState(0);

    //page 10
    const [surveyCount,setSurveyCount] =  useState(0);
    const [surveyId,setSurveyId] =  useState(null);
    const [phone,setPhone] =  useState(null);
    const [date,setDate] =  useState(null);
    const [time,setTime] =  useState(null);

    const [timeArr,setTimeArr] =  useState([]);
    
    const userApi = useGet({
        url: sessionStorage.getItem("familyId") ? `/family/profile/detail?user_family_id=${sessionStorage.getItem("familyId")}` : `/profile?is_simple=1`,
        loginType:"login"
    });
    const userApiData = sessionStorage.getItem("familyId") ? userApi?.data?.user_family : userApi?.data?.user;

    function page4ReSet(){
        setUserWeight(null);
        setDiagnosis_menu(null);

        setKidney_status(null);
        setCr_value(null);
        setGfr_value(null);

        setDiabetes_type(null);
        setFasting_blood_sugar(null);
        setAfter_meal_blood_sugar(null);
        setGlycated_hemoglobin(null);

        setBefore_6month_change_weight(null);
        setEdema(null);
        setLoss_of_appetite(null);
        setCancer_type(null);
        setAlbumin_value(null);
        setHb_value(null);
        setNeed_manage_disease([]);
    }

    function dataReSet(){
        //page 2
        setDisease(null);

        //page 3
        setConsultType(null);
        
        //page 4
        page4ReSet();

        //page 5
        setHard_dietotherapy(null);

        //page 6 7 8
        setMorningSel([]);
        setMorningList([]);

        setMorningKey(null);
        setMorningPage(1);

        setAfternoonSel([]);
        setAfternoonList([]);

        setAfternoonKey(null);
        setAfternoonPage(1);

        setEveningSel([]);
        setEveningList([]);

        setEveningKey(null);
        setEveningPage(1);

        setMorningLastPage(false);
        setMorningSettingChk(0);

        setAfternoonLastPage(false);
        setAfternoonSettingChk(0);

        setEveningLastPage(false);
        setEveningSettingChk(0);

        //page 10
        setPhone(null);
        setDate(null);
        setTime(null);

        setMorningKey(null);
        setAfternoonKey(null);
        setEveningKey(null);
    }

    function pageBack(){
        if(page === 1 || page === 2){
            dataReSet();
        }

        if(page === 3){
            page_2_btnChk();
        }else if(page === 4){
            page_3_btnChk();
        }else if(page === 5){
            page_4_btnChk();
        }else if(page === 6){
            page_5_btnChk();
        }else if(page === 7){
            page_6_btnChk();
        }else if(page === 8){
            page_7_btnChk();
        }else if(page === 9){
            page_8_btnChk();
        }else if(page === 10){
            if(surveyCount == 0){
                setBtnChk(true)
            }else{
                setBtnChk(false)
            }          
        }

        setPage(page - 1);
    }

    function pageNext(){
        if(page === 1){
            page_2_btnChk();
        }else if(page === 2){
            page_3_btnChk();
        }else if(page === 3){
            page_4_btnChk();
        }else if(page === 4){
            page_5_btnChk();
        }else if(page === 5){
            page_6_btnChk();
        }else if(page === 6){
            page_7_btnChk();
        }else if(page === 7){
            page_8_btnChk();
        }else if(page === 8){
            
        }else if(page === 9){
            page_10_btnChk();
        }else if(page === 10){
            
        }else{
            setBtnChk(true);
        }
        
        setPage(page + 1);
    }

    function surveyStart(){
        if(userApiData){
            setGender(userApiData?.gender ? String(userApiData?.gender) : null);
            setBirthday(userApiData?.birth ? String(userApiData?.birth) : null);
            setUserHeight(userApiData?.height ? String(userApiData?.height) : null);
        }

        setPage(1);
        setBtnChk(false);
    }

    function page_2_btnChk(){
        if(
            disease && disease !== ""
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }
    
    function page_3_btnChk(){
        if(
            consultType && consultType !== ""
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_4_btnChk(){
        if((gender && gender !== ""
            && birthday && birthday !== ""
            && userHeight && userHeight !== ""
            && userWeight && userWeight !== ""
            && (diagnosis_menu == 1 || diagnosis_menu == 0)
            && (disease === "신장" ? 
                (kidney_status && kidney_status !== ""
                && (disease === "신장" && (diagnosis_menu == 0 ? true
                    : diagnosis_menu == 1 ? 
                        (kidney_status == "혈액투석" ? true
                        :
                            kidney_status == "1-2형" || kidney_status == "3-5형" || kidney_status == "복막투석" ? 
                                (cr_value && cr_value !== ""
                                && gfr_value && gfr_value !== "")
                            : false)
                    : false
                )))
            : disease === "당뇨" ?
                (diagnosis_menu == 0 ? true
                    : diagnosis_menu == 1 ? 
                        (diabetes_type && diabetes_type !== ""
                        && fasting_blood_sugar && fasting_blood_sugar !== ""
                        && glycated_hemoglobin && glycated_hemoglobin !== "")
                        : false
                )
            : disease === "암" ?
                (diagnosis_menu == 0 ? true
                    : diagnosis_menu == 1 ? 
                            ((before_6month_change_weight||before_6month_change_weight === 0) && before_6month_change_weight !== ""
                            && (edema||edema === 0) && edema !== ""
                            && (loss_of_appetite||loss_of_appetite === 0) && loss_of_appetite !== ""
                            && cancer_type && cancer_type !== ""
                            && albumin_value && albumin_value !== ""
                            && hb_value && hb_value !== "")
                        : false
                )
            : true)
        )){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_5_btnChk(){
        if(
            hard_dietotherapy && hard_dietotherapy !== ""
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_6_btnChk(){
        if(
            morningSel && morningSel?.length > 0
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_6_reSet(){
        if(morningKey !== morningKeyApi){
            setMorningLastPage(false);
            setMorningSettingChk(0);

            setMorningList([]);
            setMorningPage(1);
        }

        setMorningKeyApi(morningKey);
    }

    function morning_addPage(){
        setMorningPage(morningPage + 1);
        setMorningSettingChk(1);
        setTimeout(() => {
            setMorningSettingChk(0);
        }, 300);
    };

    useEffect(() => {
        if(morningApiData?.data && morningApiData?.data.length > 0){
            if(page == 1){
                setMorningList([...morningApiData?.data]);
            }else{    
                setMorningList(prev => [...prev, ...morningApiData?.data]);
            }
        }else{
            setMorningList([]);
        }

        setMorningLastPage(morningApiData?.current_page < morningApiData?.last_page);
    }, [morningApi]);

    function handleScroll_morning(item){
        if(bottomChkEl(item) && morningLastPage && morningSettingChk == 0){
            morning_addPage();
        }
    };

    function page_7_btnChk(){
        if(
            afternoonSel && afternoonSel?.length > 0
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_7_reSet(){
        if(afternoonKey !== afternoonKeyApi){
            setAfternoonLastPage(false);
            setAfternoonSettingChk(0);

            setAfternoonList([]);
            setAfternoonPage(1);
        }

        setAfternoonKeyApi(afternoonKey);
    }

    function afternoon_addPage(){
        setAfternoonPage(afternoonPage + 1);
        setAfternoonSettingChk(1);
        setTimeout(() => {
            setAfternoonSettingChk(0);
        }, 300);
    };

    useEffect(() => {
        if(afternoonApiData?.data && afternoonApiData?.data.length > 0){
            if(page == 1){
                setAfternoonList([...afternoonApiData?.data]);
            }else{    
                setAfternoonList(prev => [...prev, ...afternoonApiData?.data]);
            }
        }else{
            setAfternoonList([]);
        }

        setAfternoonLastPage(afternoonApiData?.current_page < afternoonApiData?.last_page);
    }, [afternoonApi]);

    function handleScroll_afternoon(item){
        if(bottomChkEl(item) && afternoonLastPage && afternoonSettingChk == 0){
            afternoon_addPage();
        }
    };

    function page_8_btnChk(){
        if(
            eveningSel && eveningSel?.length > 0
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_8_reSet(){
        if(eveningKey !== eveningKeyApi){
            setEveningLastPage(false);
            setEveningSettingChk(0);

            setEveningList([]);
            setEveningPage(1);
        }

        setEveningKeyApi(eveningKey);
    }

    function evening_addPage(){
        setEveningPage(eveningPage + 1);
        setEveningSettingChk(1);
        setTimeout(() => {
            setEveningSettingChk(0);
        }, 300);
    };

    useEffect(() => {
        if(eveningApiData?.data && eveningApiData?.data.length > 0){
            if(page == 1){
                setEveningList([...eveningApiData?.data]);
            }else{    
                setEveningList(prev => [...prev, ...eveningApiData?.data]);
            }
        }else{
            setEveningList([]);
        }

        setEveningLastPage(eveningApiData?.current_page < eveningApiData?.last_page);
    }, [eveningApi]);

    function handleScroll_evening(item){
        if(bottomChkEl(item) && eveningLastPage && eveningSettingChk == 0){
            evening_addPage();
        }
    };

    function itemDel(type,selItems,delItem){
        let oldItem = [...selItems]
        oldItem = oldItem.filter((el)=>el.id !== delItem);

        if(type === "morning"){
            setMorningSel(oldItem);
        }else if(type === "afternoon"){
            setAfternoonSel(oldItem);
        }else if(type === "evening"){
            setEveningSel(oldItem);
        }
    }

    function itemSel(type,selItems,selItem){
        let oldItem = [...selItems]

        if(oldItem?.filter((el)=>el.id == selItem.id)[0]){
            window.errMsg("이미 선택된 재료 입니다.");
        }else{
            oldItem.push({
                ...selItem,
                gram:100
            })
        }

        if(type === "morning"){
            setMorningSel(oldItem);
        }else if(type === "afternoon"){
            setAfternoonSel(oldItem);
        }else if(type === "evening"){
            setEveningSel(oldItem);
        }
    }

    function itemGramCh(type,selItems,itemId,val){
        let oldData = [...selItems]

        if(oldData?.filter((el) => el.id === itemId)[0]){
            oldData = oldData?.map((subItem) => subItem.id === itemId ? 
                {
                    ...subItem,
                    gram: val
                } 
            : subItem)
        }

        if(type === "morning"){
            setMorningSel(oldData);
        }else if(type === "afternoon"){
            setAfternoonSel(oldData);
        }else if(type === "evening"){
            setEveningSel(oldData);
        }
    }

    function page_10_btnChk(){
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(phoneChk.test(phone?.replace(/\-/g,"")) && date && time){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }
    
    function surveySubmit(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            if(type !== "my"){
                formData.append("user_family_id", type);
            }

            //page 2
            formData.append("disease", disease);

            //page 3
            formData.append("consult_type", consultType);

            //page 4
            formData.append("gender", gender);
            formData.append("birth", birthday);
            formData.append("height", userHeight);
            formData.append("weight", userWeight);
            formData.append("diagnosis_menu", diagnosis_menu);

            if(disease === "신장" && (diagnosis_menu == 1 || diagnosis_menu == 0)){
                formData.append("kidney_status", kidney_status);

                if((kidney_status === "1-2형" || kidney_status === "3-5형" || kidney_status === "복막투석") && diagnosis_menu == 1){
                    formData.append("cr_value", cr_value);
                    formData.append("gfr_value", gfr_value);
                }
            }

            if(disease === "당뇨" && diagnosis_menu == 1){
                formData.append("diabetes_type", diabetes_type);
                formData.append("fasting_blood_sugar", fasting_blood_sugar);
                if(after_meal_blood_sugar || after_meal_blood_sugar === 0){
                    formData.append("after_meal_blood_sugar", after_meal_blood_sugar)
                }
                formData.append("glycated_hemoglobin", glycated_hemoglobin);
            }

            if(disease === "암" && diagnosis_menu == 1){
                formData.append("before_6month_change_weight", before_6month_change_weight);
                formData.append("edema", edema);
                formData.append("loss_of_appetite", loss_of_appetite);
                formData.append("cancer_type", cancer_type);
                
                formData.append("albumin_value", albumin_value);
                formData.append("hb_value", hb_value);

                if(need_manage_disease?.length > 0){
                    formData.append("need_manage_disease", need_manage_disease?.join());
                }
            }

            //page 5
            formData.append("hard_dietotherapy", hard_dietotherapy);
            
            //page 6 7 8
            morningSel?.forEach(function(item,i){
                formData.append(`morning_ingredient_ids[]`, item.id);
                formData.append(`morning_ingredient_weights[]`, item.gram);
            });

            afternoonSel?.forEach(function(item,i){
                formData.append(`afternoon_ingredient_ids[]`, item.id);
                formData.append(`afternoon_ingredient_weights[]`, item.gram);
            });

            eveningSel?.forEach(function(item,i){
                formData.append(`evening_ingredient_ids[]`, item.id);
                formData.append(`evening_ingredient_weights[]`, item.gram);
            });

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/consult/nutrition/register",
                success: (data) => {
                    setSubmitChk(true);
                    setSurveyId(data?.data?.nutrition_consult_id);
                    setSurveyCount(data?.data?.survey_use_count);

                    if(data?.data?.survey_use_count == 0){
                        setBtnChk(true)
                    }else{
                        setBtnChk(false)
                    }

                    setPage(page + 1);
                },
                err: (data) => {
                    setSubmitChk(true);
                    window.errPopupOpen(data);
                }
            })
        }
    }

    function surveyCsSubmit(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            formData.append("nutrition_consult_id", surveyId);

            //page 10
            formData.append("phone", phone?.replace(/-/g,""));
            formData.append("date", date);
            formData.append("time", time);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/consult/nutrition/update",
                success: (data) => {
                    setSubmitChk(true);

                    setBtnChk(false)

                    setPage(page + 1);
                },
                err: (data) => {
                    setSubmitChk(true);
                    window.errPopupOpen(data);
                }
            })
        }
    }

    function cancelSurvey(id){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();
            formData.append("nutrition_consult_id", id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/consult/nutrition/cancel",
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"상담 취소",
                        text:"예약하신 상담이 취소 되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {
                            setPopupData(null)
                            window.location.reload(true);
                        },
                        btnFunc0:(e)=>{
                            setPopupData(null)
                            window.location.reload(true);
                        },
                        btn0:"확인",
                        btn0Type:"",
                        btn1:null,
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    window.errPopupOpen(data);
                }
            })
        }
    }

    useEffect(() => {
        if(page === 2){
            page_2_btnChk();
        }
        page4ReSet();
    }, [disease]);

    useEffect(() => {
        if(page === 3){
            page_3_btnChk();
        }
    }, [consultType]);

    useEffect(() => {
        if(page === 4){
            page_4_btnChk();
        }
    }, [gender,birthday,userHeight,userWeight,diagnosis_menu,kidney_status,cr_value,gfr_value,diabetes_type,fasting_blood_sugar,after_meal_blood_sugar,glycated_hemoglobin,before_6month_change_weight,edema,loss_of_appetite,cancer_type,need_manage_disease,albumin_value,hb_value]);

    useEffect(() => {
        if(page === 5){
            page_5_btnChk();
        }
    }, [hard_dietotherapy]);

    useEffect(() => {
        if(page === 6){
            page_6_btnChk();
        }
    }, [morningSel]);

    useEffect(() => {
        if(page === 7){
            page_7_btnChk();
        }
    }, [afternoonSel]);

    useEffect(() => {
        if(page === 8){
            page_8_btnChk();
        }
    }, [eveningSel]);

    useEffect(() => {
        if(page === 10){
            page_10_btnChk();
        }
    }, [phone,date,time,surveyId]);

    useEffect(() => {
        let timeData = [10,11,12,1,2,3,4,5];
        let timeSet = [];
        timeData.forEach(function(item,i){
            let begin = item >= 10 ? `오전 ${item}:00` : `오후 0${item}:00`,
                endTime = item === 12 ? 1 : item + 1,
                end =  endTime >= 10 ? `오전 ${endTime}:00` : `오후 0${endTime}:00`;

            timeSet.push({
                id:`${begin} ~ ${end}`,
                name:`${begin} ~ ${end}`
            });

            if(timeData.length - 1 === i){
                setTimeArr(timeSet);
            }
        })
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn_r2={true}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                {page === 0 ? 
                    <>
                    <TermsContentsBox addClass="pageType">
                        {nutrition_consults && nutrition_consults?.length > 0 ? 
                            <>
                            <TermsTitle title={"상담 예약 내역"}/>
                            <div className="nutritionSurvey_slideList mContents">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    autoHeight={true}
                                    loop={true}
                                >
                                    {nutrition_consults && nutrition_consults?.map((item,i)=>(
                                        <SwiperSlide key={i}>
                                            <div className="surveyListItmeBox">
                                                <SurveyListTr
                                                    data={item}
                                                    func={()=>{
                                                        if(item.status === 0){
                                                            setPopupData({
                                                                addClass:null,
                                                                title:"상담 취소",
                                                                text:"예약하신 상담을 취소하시겠습니까?<br/>취소한 예약은 복구할 수 없어요.",
                                                                closeType:true,
                                                                closeFunc:(e) => {setPopupData(null)},
                                                                btnFunc0:(e)=>{setPopupData(null)},
                                                                btn0:"닫기",
                                                                btn0Type:"line",
                                                                btn1:"예약 취소",
                                                                btn1Type:"",
                                                                btnFunc1:()=>{setPopupData(null);cancelSurvey(item.id)}
                                                            });
                                                        }else if(item.status === 1){
                                                            navigate(`/nutrition/detail/${item.id}`);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                            <TableSection
                                headType={true}
                                headAddClass="pcContents"
                                addClass="pcContents nutritionSurvey_table"
                                th={[
                                    {
                                        style:{minWidth:"12.2%",width:"12.2%"},
                                        contents:`예약일시`
                                    },
                                    {
                                        style:{minWidth:"6.6%",width:"6.6%"},
                                        contents:`성별`
                                    },
                                    {
                                        style:{minWidth:"8.1%",width:"8.1%"},
                                        contents:`출생년도`
                                    },
                                    {
                                        style:{minWidth:"6.6%",width:"6.6%"},
                                        contents:`키`
                                    },
                                    {
                                        style:{minWidth:"6.6%",width:"6.6%"},
                                        contents:`몸무게`
                                    },
                                    {
                                        style:{minWidth:"11.5%",width:"11.5%"},
                                        contents:`콩팥상태`
                                    },
                                    {
                                        style:{minWidth:"11.5%",width:"11.5%"},
                                        contents:`크레아티닌(Cr) 수치`
                                    },
                                    {
                                        style:{minWidth:"11.5%",width:"11.5%"},
                                        contents:`사구체 여과율`
                                    },
                                    {
                                        style:{minWidth:"12.7%",width:"12.7%"},
                                        contents:`상담 상태`
                                    },
                                    {
                                        style:{minWidth:"12.7%",width:"12.7%"},
                                        contents:`결과 보기`
                                    },
                                ]}
                            >
                                {nutrition_consults && nutrition_consults?.length > 0 ? 
                                <>
                                {nutrition_consults?.map((item,i)=>(
                                    <SurveyListTr
                                        key={i}
                                        data={item}
                                        func={()=>{
                                            if(item.status === 0){
                                                setPopupData({
                                                    addClass:null,
                                                    title:"상담 취소",
                                                    text:"예약하신 상담을 취소하시겠습니까?<br/>취소한 예약은 복구할 수 없어요.",
                                                    closeType:true,
                                                    closeFunc:(e) => {setPopupData(null)},
                                                    btnFunc0:(e)=>{setPopupData(null)},
                                                    btn0:"닫기",
                                                    btn0Type:"line",
                                                    btn1:"예약 취소",
                                                    btn1Type:"",
                                                    btnFunc1:()=>{setPopupData(null);cancelSurvey(item.id)}
                                                });
                                            }else if(item.status === 1){
                                                navigate(`/nutrition/detail/${item.id}`);
                                            }
                                        }}
                                    />
                                ))}
                                </>
                                :""}
                            </TableSection>
                            </>
                        :""}

                        <TermsTitle title={"영양 상담 안내"}/>
                        <TermsContents contents={nutrition_consult_contents}/>
                    </TermsContentsBox>
                    <BtnBox
                        boxType="fixed"
                        addClass="pcFixedBtnBox row2"
                    >
                        <BtnItem
                            addClass=""
                            contents={"영양 상담 시작하기"}
                            func={() => {surveyStart()}}
                        />
                    </BtnBox>
                    </>
                :""}
                {page !== 0 ? 
                    <>
                    <InputContents
                        title={
                            page === 1 ? "영양 상담 시작하기"
                            : page === 2 ? "질환정보 선택"
                            : page === 3 ? "상담 유형 선택"
                            : page === 4 ? "식사요법 하시는 분"
                            : page === 5 ? "식사요법 정보"
                            : page === 6 ? "아침에 어떤 것을 드셨나요?"
                            : page === 7 ? "점심에 어떤 것을 드셨나요?"
                            : page === 8 ? "저녁에 어떤 것을 드셨나요?"
                            : page === 9 ? "서베이 완료!"
                            : page === 10  ? "상담일시 선택하기"
                            : page === 11  ? "영양상담 예약 완료"
                            : ""
                        }
                        subTitle={
                            page === 1 ? "고객님의 영양 관리 상담을 위해 서베이를 진행할게요,<br/>서베이는 약 5분정도 소요됩니다."
                            : page === 2 ? `${userApiData?.name} 님이 현재 겪고계시는 질환을 선택해주세요.`
                            : page === 3 ? `어떤 유형의 상담을 받아보시겠어요?`
                            : page === 4 ? "드시는 분의 정보를 선택/입력해 주세요!"
                            : page === 5 ? "식사요법을 하면서 가장 힘든 점은 무엇인가요?"
                            : page === 6 ? "아침에 드신 음식 재료들을 선택해주세요.<br/>선택한 재료의 용량은 100g 단위로 선택해야해요."
                            : page === 7 ? "점심에 드신 음식 재료들을 선택해주세요.<br/>선택한 재료의 용량은 100g 단위로 선택해야해요."
                            : page === 9  ? "서베이가 모두 완료되어 건강 데이터에 등록되었습니다.<br/>등록해주신 건강 데이터를 바탕으로 임상영양사 선생님과 영양 상담을 진행해 보세요."
                            : page === 10  ? "상담은 직접 상담사가 연락드려요.<br/>연락 받으실 정보와 가능하신 상담일시를 선택해 주세요."
                            : page === 11  ? "상담 예약이 완료되었습니다.<br/>임상영양사 선생님이 고객님의 건강 데이터를 분석하고<br/>예약하신 일시에 맞춰 연락드릴게요.<br/><span class='input_contents_subTitleCaption'>분석이 지연될 경우 상담일시가 변경될 수 있습니다</span>"
                            :""
                        }
                        icon={
                            page === 6 ? "/assets/images/img/survey_title_icon_0.svg"
                            : page === 7 ? "/assets/images/img/survey_title_icon_1.svg"
                            : page === 8 ? "/assets/images/img/survey_title_icon_2.svg"
                            : null
                        }
                    >
                        {page === 1 ?
                            <img src="/assets/images/img/survey_img.png" className="survey_img"/>
                        :""}
                        {page === 2 ? 
                            <InputBoxOnly addClass="" inputName={null}>
                                <InputTap
                                    addClass="diseaseTap"
                                    data={[
                                        {
                                            id:"신장",
                                            name:"신장 질환",
                                            addClass:"oColor opacityActive"
                                        },
                                        {
                                            id:"당뇨",
                                            name:"당뇨 질환",
                                            addClass:"bColor opacityActive"
                                        },
                                        {
                                            id:"암",
                                            name:"암 질환",
                                            addClass:"gColor opacityActive"
                                        }
                                    ]}
                                    val={disease}
                                    func={(value) => {setDisease(value)}}
                                />
                            </InputBoxOnly>
                        :""}

                        {page === 3 ? 
                            <InputBoxOnly addClass="" inputName={null}>
                                <SurveyTypeBtn
                                    id="내 몸에 맞는 다량 영양소"
                                    val={consultType}
                                    func={()=>{setConsultType("내 몸에 맞는 다량 영양소")}}
                                    name="내 몸에 맞는 <span class='oTextColor'>다량 영양소</span>"
                                    text="나에게 맞는 당질(탄수화물), 단백질, 지방의 적정 섭취량 상담"
                                />
                                <SurveyTypeBtn
                                    id="조절이 필요한 영양 성분"
                                    val={consultType}
                                    func={()=>{setConsultType("조절이 필요한 영양 성분")}}
                                    name="조절이 필요한 <span class='oTextColor'>영양 성분</span>"
                                    text="나트륨, 단백질, 칼륨, 인 등 조절해야하는 영양성분에 대한 기초 교육"
                                />
                                <SurveyTypeBtn
                                    id="식사에 도움을 주는 미량 영양소와 간식"
                                    val={consultType}
                                    func={()=>{setConsultType("식사에 도움을 주는 미량 영양소와 간식")}}
                                    name="식사에 도움을 주는 <span class='oTextColor'>미량 영양소와 간식</span>"
                                    text="채소, 과일 등 적정한 칼륨 섭취와 적정한 염분 섭취 및 올바른 간식(열량)섭취에 대한 식사 상담"
                                />
                            </InputBoxOnly>
                        :""}

                        {page === 4 ? 
                            <>
                                <InputBoxOnly addClass="" inputName="드시는 분의 성별">
                                    <InputTap
                                        data={[
                                            {
                                                id:"m",
                                                name:"남성"
                                            },
                                            {
                                                id:"f",
                                                name:"여성"
                                            }
                                        ]}
                                        val={gender}
                                        func={(value) => {setGender(value)}}
                                    />
                                </InputBoxOnly>
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="드시는 분의 출생년도"
                                    placeholder="예 : 1990"
                                    value={birthday}
                                    birthYY={true}
                                    unit={"년"}
                                    max={4}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setBirthday(value)}}
                                />
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="키"
                                        placeholder="예 : 165"
                                        value={userHeight}
                                        max={3}
                                        unit={"cm"}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{setUserHeight(value)}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="몸무게"
                                        placeholder="예 : 50"
                                        value={userWeight}
                                        max={3}
                                        unit={"kg"}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{setUserWeight(value)}}
                                    />
                                </InputBoxCol2>
                                <InputBoxOnly addClass="" inputName="병원에서 진단받은 내용을 바탕으로 식단을 드시겠어요?">
                                    <InputTap
                                        data={[
                                            {
                                                addClass:"alignLeft",
                                                id:1,
                                                name:"<b>예,</b><br/>병원에서 진단받은 내용으로 식단을 먹겠습니다."
                                            },
                                            {
                                                addClass:"alignLeft",
                                                id:0,
                                                name:"<b>아니오,</b><br/>진단받은 내용이 없습니다.<br/>일반 저염식을 원합니다.",
                                            }
                                        ]}
                                        val={diagnosis_menu}
                                        func={(value) => {setDiagnosis_menu(value)}}
                                    />
                                </InputBoxOnly>
                                {disease === "신장" && (diagnosis_menu == 1 || diagnosis_menu == 0) ?
                                    <>
                                        <InputBoxOnly addClass="" inputName="진단받은 콩팥상태 선택">
                                            <InputTap
                                                data={[
                                                    {
                                                        id:"1-2형",
                                                        name:"1-2형"
                                                    },
                                                    {
                                                        id:"3-5형",
                                                        name:"3-5형",
                                                    },
                                                    {
                                                        id:"혈액투석",
                                                        name:"혈액투석",
                                                    },
                                                    {
                                                        id:"복막투석",
                                                        name:"복막투석",
                                                    }
                                                ]}
                                                val={kidney_status}
                                                func={(value) => {setKidney_status(value)}}
                                            />
                                        </InputBoxOnly>
                                        {(kidney_status === "1-2형" || kidney_status === "3-5형" || kidney_status === "복막투석") && diagnosis_menu == 1 ? 
                                            <>
                                            <InputItemBox
                                                inputType={null}
                                                addClass=""
                                                inputName="크레아티닌(Cr) 수치"
                                                placeholder="예 : 2.0"
                                                value={cr_value}
                                                max={null}
                                                unit={"mg/dL"}
                                                regexp={/[^0123456789\.]/gi}
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                func={(value)=>{setCr_value(value)}}
                                            />
                                            <InputItemBox
                                                inputType={null}
                                                addClass=""
                                                inputName="사구체여과율"
                                                placeholder="0"
                                                value={gfr_value}
                                                max={null}
                                                unit={"%"}
                                                regexp={"number"}
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                func={(value)=>{setGfr_value(value)}}
                                            />
                                            <SurveyInfo func={()=>{}} title="크레아티닌(Cr) 수치는 어떻게 확인하나요?" text="크레아티닌은 혈액 검사를 통해 알 수 있어요.<br/>검사 결과지에서 크레아티닌 수치를 확인 후 입력해주세요."/>
                                            <SurveyInfo func={()=>{}} title="사구체여과율은 어떤 방식으로 계산되나요?" text="사구체여과율은 고객님의 나이, 성별, 신체정보를 바탕으로 계산하며, CKD-EPI 공식을 사용하고 있습니다."/>
                                            </>
                                        : ""}
                                    </>
                                :""}

                                {disease === "당뇨" && diagnosis_menu == 1 ?
                                    <>
                                        <InputBoxOnly addClass="" inputName={"진단받은 당뇨 유형"}>
                                            <InputTap
                                                addClass=""
                                                data={[
                                                    {
                                                        id:"1형",
                                                        name:"1형",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"2형",
                                                        name:"2형",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"임신성",
                                                        name:"임신성",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"모르겠어요",
                                                        name:"모르겠어요",
                                                        addClass:""
                                                    }
                                                ]}
                                                val={diabetes_type}
                                                func={(value) => {setDiabetes_type(value)}}
                                            />
                                        </InputBoxOnly>
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="공복 혈당"
                                            placeholder="예 : 80"
                                            value={fasting_blood_sugar}
                                            max={null}
                                            unit={"mg/dL"}
                                            regexp={"number"}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{setFasting_blood_sugar(value)}}
                                        />
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="식후 2시간 뒤 혈당 <span>(선택)</span>"
                                            placeholder="예 : 80"
                                            value={after_meal_blood_sugar}
                                            max={null}
                                            unit={"mg/dL"}
                                            regexp={"number"}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{setAfter_meal_blood_sugar(value)}}
                                        />
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="당화혈색소"
                                            placeholder="예 : 5.7"
                                            value={glycated_hemoglobin}
                                            max={null}
                                            unit={"%"}
                                            regexp={/[^0123456789\.]/gi}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{setGlycated_hemoglobin(value)}}
                                        />
                                        <SurveyInfo func={()=>{}} title="당화혈색소가 무엇인가요?" text="최근 2-3개월의 평균 혈당치로 조절 상태를 파악할 수 있는 지표입니다."/>
                                    </>
                                :""}

                                {disease === "암" && diagnosis_menu == 1 ?
                                    <>
                                        <InputBoxOnly addClass="" inputName={"최근 6개월 사이에 눈에 띄는 체중 변화가 있었나요?"}>
                                            <InputTap
                                                addClass=""
                                                data={[
                                                    {
                                                        id:"1",
                                                        name:"네",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"0",
                                                        name:"아니오",
                                                        addClass:""
                                                    }
                                                ]}
                                                val={before_6month_change_weight}
                                                func={(value) => {setBefore_6month_change_weight(value)}}
                                            />
                                        </InputBoxOnly>
                                        <InputBoxOnly addClass="" inputName={"부종 또는 복수의 증상이 있으신가요?"}>
                                            <InputTap
                                                addClass=""
                                                data={[
                                                    {
                                                        id:"1",
                                                        name:"네",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"0",
                                                        name:"아니오",
                                                        addClass:""
                                                    }
                                                ]}
                                                val={edema}
                                                func={(value) => {setEdema(value)}}
                                            />
                                        </InputBoxOnly>
                                        <InputBoxOnly addClass="" inputName={"식욕부진이 있으신가요?"}>
                                            <InputTap
                                                addClass=""
                                                data={[
                                                    {
                                                        id:"1",
                                                        name:"네",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"0",
                                                        name:"아니오",
                                                        addClass:""
                                                    }
                                                ]}
                                                val={loss_of_appetite}
                                                func={(value) => {setLoss_of_appetite(value)}}
                                            />
                                        </InputBoxOnly>
                                        <InputBoxOnly addClass="" inputName={"진단받은 질환을 선택해 주세요."}>
                                            <InputTap
                                                addClass=""
                                                data={[
                                                    {
                                                        id:"간암",
                                                        name:"간암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"갑상선암",
                                                        name:"갑상선암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"대장암",
                                                        name:"대장암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"유방암",
                                                        name:"유방암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"위암",
                                                        name:"위암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"자궁경부암",
                                                        name:"자궁경부암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"폐암",
                                                        name:"폐암",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"기타",
                                                        name:"기타",
                                                        addClass:""
                                                    },
                                                ]}
                                                val={cancer_type}
                                                func={(value) => {setCancer_type(value)}}
                                            />
                                        </InputBoxOnly>
                                        <InputBoxOnly addClass="" inputName={"암 질환 외 관리가 필요한 질환이 있나요? (중복 선택)"}>
                                            <InputTap
                                                addClass=""
                                                multi={true}
                                                data={[
                                                    {
                                                        id:"간 질환",
                                                        name:"간 질환",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"고혈압",
                                                        name:"고혈압",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"고콜레스테롤혈증",
                                                        name:"고콜레스테롤혈증",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"고중성지방혈증",
                                                        name:"고중성지방혈증",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"골다공증",
                                                        name:"골다공증",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"당뇨병",
                                                        name:"당뇨병",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"기타",
                                                        name:"기타",
                                                        addClass:""
                                                    },
                                                    {
                                                        id:"없음",
                                                        name:"없음",
                                                        addClass:""
                                                    }
                                                ]}
                                                val={need_manage_disease}
                                                func={(value) => {
                                                    let oldData = [...need_manage_disease];
                                                    if(oldData?.filter((el) => el === value)[0]){
                                                        oldData = oldData?.filter((el) => el !== value);
                                                    }else{
                                                        oldData.push(value)
                                                    }

                                                    setNeed_manage_disease(oldData)
                                                }}
                                            />
                                        </InputBoxOnly>
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="알부민 수치"
                                            placeholder="예 : 3.5"
                                            value={albumin_value}
                                            max={null}
                                            unit={"g/dL"}
                                            regexp={/[^0123456789\.]/gi}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{setAlbumin_value(value)}}
                                        />
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="헤모글로빈 (Hb) 수치"
                                            placeholder="예 : 13.5"
                                            value={hb_value}
                                            max={null}
                                            unit={"g/dL"}
                                            regexp={/[^0123456789\.]/gi}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{setHb_value(value)}}
                                        />
                                        <SurveyInfo func={()=>{}} title="알부민 (Albumin) 수치가 무엇인가요?" text="최근 한 달 이내의 영양 상태를 반영하는 중요한 지표입니다."/>
                                        <SurveyInfo func={()=>{}} title="헤모글로빈 (Hemoglobin) 수치가 무엇인가요?" text="빈혈의 지표로 영양 상태 반영 지표입니다."/>
                                    </>
                                :""}

                                <SurveyInfo func={()=>{}} title="특이사항 안내" text="">
                                    <p className="surveyInfoText">
                                        당뇨병, 고혈압/이상지혈증, 통풍(요산수치증가)이나 기타 알러지가 있으신 분들은 <span onClick={()=>{
                                            //채널톡 오픈
                                            ChannelShowMessenger();
                                        }} class='oTextColor underLineText'>채팅</span>을 통해 식사 제한 사항에 대해 알려주세요.
                                    </p>
                                </SurveyInfo>
                            </>
                        :""}

                        {page === 5 ? 
                            <InputBoxOnly addClass="" inputName={null}>
                                <SurveyTypeBtn
                                    id="나에게 맞는 제한 양"
                                    val={hard_dietotherapy}
                                    func={()=>{setHard_dietotherapy("나에게 맞는 제한 양")}}
                                    name="나에게 맞는 <span class='oTextColor'>제한 양</span>"
                                    text="제한하는게 많은데, 나에게 맞는 제한 양을 모르겟어요."
                                />
                                <SurveyTypeBtn
                                    id="영양성분 정보"
                                    val={hard_dietotherapy}
                                    func={()=>{setHard_dietotherapy("영양성분 정보")}}
                                    name="<span class='oTextColor'>영양성분</span> 정보"
                                    text="식재료 안에 들어있는 영양성분 양을 모르겠어요."
                                />
                                <SurveyTypeBtn
                                    id="잦은 외식"
                                    val={hard_dietotherapy}
                                    func={()=>{setHard_dietotherapy("잦은 외식")}}
                                    name="잦은 <span class='oTextColor'>외식</span>"
                                    text="외식이 많아 식사요법 유지가 어렵습니다."
                                />
                                <SurveyTypeBtn
                                    id="어려운 조리법"
                                    val={hard_dietotherapy}
                                    func={()=>{setHard_dietotherapy("어려운 조리법")}}
                                    name="어려운 <span class='oTextColor'>조리법</span>"
                                    text="조리법이 너무 까다로워요."
                                />
                                <SurveyTypeBtn
                                    id="식욕 부진"
                                    val={hard_dietotherapy}
                                    func={()=>{setHard_dietotherapy("식욕 부진")}}
                                    name="<span class='oTextColor'>식욕</span> 부진"
                                    text="환자식은 맛이 없어 식욕이 떨어집니다."
                                />
                            </InputBoxOnly>
                        :""}

                        {page === 6 ? 
                            <>
                                <SurveySelect
                                    inputType={null}
                                    addClass=""
                                    inputName="재료 검색"
                                    placeholder="재료 명을 검색해주세요."
                                    value={morningKey}
                                    func={(value)=>{setMorningKey(value)}}

                                    shFunc={()=>{page_6_reSet()}}

                                    boxScrollFunc={(item)=>{handleScroll_morning(item)}}

                                    options={morningList}
                                    optionValue={morningSel}
                                    optionFunc={(item) => {
                                        itemSel("morning",morningSel,item)
                                    }}
                                />
                                {morningSel && morningSel?.length > 0 ? 
                                    <SurveySelectSelItem
                                        data={morningSel}
                                        countFunc={(id,val)=>{itemGramCh("morning",morningSel,id,val)}}
                                        delFunc={(id)=>{itemDel("morning",morningSel,id)}}
                                    />
                                :""}
                            </>
                        :""}

                        {page === 7 ? 
                            <>
                                <SurveySelect
                                    inputType={null}
                                    addClass=""
                                    inputName="재료 검색"
                                    placeholder="재료 명을 검색해주세요."
                                    value={afternoonKey}
                                    func={(value)=>{setAfternoonKey(value)}}

                                    shFunc={()=>{page_7_reSet()}}

                                    boxScrollFunc={(item)=>{handleScroll_afternoon(item)}}

                                    options={afternoonList}
                                    optionValue={afternoonSel}
                                    optionFunc={(item) => {
                                        itemSel("afternoon",afternoonSel,item)
                                    }}
                                />
                                {afternoonSel && afternoonSel?.length > 0 ? 
                                    <SurveySelectSelItem
                                        data={afternoonSel}
                                        countFunc={(id,val)=>{itemGramCh("afternoon",afternoonSel,id,val)}}
                                        delFunc={(id)=>{itemDel("afternoon",afternoonSel,id)}}
                                    />
                                :""}
                            </>
                        :""}

                        {page === 8 ? 
                            <>
                                <SurveySelect
                                    inputType={null}
                                    addClass=""
                                    inputName="재료 검색"
                                    placeholder="재료 명을 검색해주세요."
                                    value={eveningKey}
                                    func={(value)=>{setEveningKey(value)}}

                                    shFunc={()=>{page_8_reSet()}}

                                    boxScrollFunc={(item)=>{handleScroll_evening(item)}}

                                    options={eveningList}
                                    optionValue={eveningSel}
                                    optionFunc={(item) => {
                                        itemSel("evening",eveningSel,item)
                                    }}
                                />
                                {eveningSel && eveningSel?.length > 0 ? 
                                    <SurveySelectSelItem
                                        data={eveningSel}
                                        countFunc={(id,val)=>{itemGramCh("evening",eveningSel,id,val)}}
                                        delFunc={(id)=>{itemDel("evening",eveningSel,id)}}
                                    />
                                :""}
                            </>
                        :""}

                        {page === 9 ?
                            <img src="/assets/images/img/survey_img_1.png" className="survey_img"/>
                        :""}

                        {page === 10 ?
                            <>
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="상담 받을 연락처"
                                    placeholder="상담 받으실 분의 연락처"
                                    value={phone}
                                    max={13}
                                    phoneSetting={true}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setPhone(value)}}
                                />
                                <CustomSelect
                                    addClass=""
                                    inputName="상담 가능 시간"
                                    placeholder="상담 가능 시간을 선택해주세요."
                                    value={time}
                                    func={(name,id) => {
                                        setTime(id);
                                    }}
                                    disabled={false}
                                    valKey="id"
                                    nameKey="name"
                                    options={timeArr}
                                />
                                <DatePicer
                                    inputName="상담 가능 일자"
                                    placeholder="상담 가능 일자를 선택해주세요."
                                    value={date}
                                    minCount={1}
                                    maxDate={null}
                                    inline={false}
                                    excludeDates={[]}
                                    func={(date) => {
                                        setDate(setDateTime(date,"yymmdd","hymd"));
                                    }}
                                    prevMonth={(date) => {}}
                                    nextMonth={(date) => {}}
                                />
                            </>
                        :""}

                        {page === 11 ?
                            <img src="/assets/images/img/survey_img_2.png" className="survey_img"/>
                        :""}

                        <BtnBox
                            boxType="fixed"
                            addClass={`pcBasicBtnBox ${page !== 11 ? "row2" : ""}`}
                        >
                            <BtnItem
                                addClass=""
                                contents={page === 1 ? "서베이 시작" : page === 9 ? `영양 상담 예약하기<br/><span class='minText'>(나의 상담 이용권 ${surveyCount}개)</span>` : page === 11 ? "확인" : "다음"}
                                disabled={btnChk}
                                func={() => {
                                    if(page === 8){
                                        surveySubmit()
                                    }else if(page === 10){
                                        //상담 예약
                                        surveyCsSubmit()
                                    }else if(page === 11){
                                        //상담 예약 완료
                                        navigate("/market");
                                    }else{
                                        pageNext()
                                    }
                                }}
                            />
                            {page !== 11 ? 
                            <BtnItem
                                addClass="line"
                                contents={page === 1 || page === 9 ? "취소" : "뒤로"}
                                disabled={false}
                                func={() => {
                                    if(page === 9){
                                        navigate("/market");
                                    }else{
                                        pageBack()
                                    }
                                }}
                            />
                            :""}
                        </BtnBox>
                    </InputContents>
                    </>
                :""}
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </>
    );
};

export default NutritionSurvey;