import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { MainBannerControll, MainDataArea, MainDataHealthInfoItem, MainDataInfoItem, MainDataSection, MainDataSubText, MainDataSubTitle, MainDataTitle, MainDataTitleBox, MainNotMember, MainSubBanner } from "component/main/items";
import { SubProductList, SubProductListSlide } from "component/product/items";
import { CustomPopup, LoadingBox } from "component/basic/popup";
import { FamilyItem, FamilyItemAddBtn } from "component/mypage/family";
import { BtnBox, BtnItem } from "component/basic/btns";
import { bmiChk, userWeightChk } from "js/function";
import { DataBtn } from "component/basic";

const Main = (props) => {
    const navigate = useNavigate();

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(true);

    const [familyId,setFamilyId] =  useState(sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : null);
    const [tap,setTap] =  useState(null);
    const [customPopupData,setCustomPopupData] =  useState(null);

    const mainApi = useGet({
        url:`/care/home?user_family_id=${sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : ""}&category_id=${tap}`,
        loginType:"login"
    });
    const mainApiData = mainApi?.data;
    const care_main_banners = mainApiData?.care_main_banners;
    const care_sub_banner = mainApiData?.care_sub_banner;
    const user_info = mainApiData?.user_info;

    const categoryApi = useGet({
        url:localStorage.getItem("token") && user_info ? `/recipe/category` : null,
        loginType:"login"
    });
    const categoryApiData = categoryApi?.data?.recipe_categories;

    const familyApi = useGet({
        url:localStorage.getItem("token") ? `/family/profile` : null,
        loginType:"login"
    });
    const familyApiData = familyApi?.data?.user_families;

    const profileDataApi = useGet({
        url:`/profile`,
        loginType:"login"
    });
    const user = profileDataApi?.data?.user;

    const target_recipes = mainApiData?.target_recipes;
    const customize_products = mainApiData?.customize_products;
    const popular_recipes = mainApiData?.popular_recipes;
    
    useEffect(() => {
        if(categoryApiData && categoryApiData[0]){
            setTap(categoryApiData[0]?.id||null);
        }
    }, [categoryApi]);

    useEffect(() => {
        setLoadingBoxOpen(true);
    }, []);

    useEffect(() => {
        if(mainApiData){
            setLoadingBoxOpen(false);
        }

        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 10000);
    }, [mainApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <div className="mainPage">
                    <div className="mainBanner">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            autoHeight={true}
                            loop={true}
                        >
                            {care_main_banners && care_main_banners?.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <a href={item.url} target="_blank" style={{"backgroundImage":`url('${item.image_url}')`}} className="backgroundImg mainBannerImg"/>
                                </SwiperSlide>
                            ))}
                            <MainBannerControll/>
                        </Swiper>
                    </div>
                    {localStorage.getItem("token") ? 
                    <MainDataSection>
                        {user_info && user_info?.health_data ? 
                            <>
                                <MainDataArea addClass="row1">
                                    <MainDataTitleBox>
                                        <MainDataTitle title={`${user_info?.name||"고객"} 님`}/>
                                        <DataBtn func={()=>{
                                            setCustomPopupData({
                                                addClass:null,
                                                title:"가족 선택하기",
                                                closeType:true,
                                                closeFunc:(e) => {setCustomPopupData(null)}
                                            });
                                        }}>가족 선택</DataBtn>
                                    </MainDataTitleBox>
                                    <div className="mainDataInfo">
                                        <MainDataInfoItem
                                            name="키"
                                            val={user_info?.health_data?.height||"-"}
                                            unit="cm"
                                        />
                                        <MainDataInfoItem
                                            name="체중"
                                            val={user_info?.health_data?.weight||"-"}
                                            unit="kg"
                                        />
                                    </div>
                                </MainDataArea>
                                <MainDataArea addClass="row3">
                                    <MainDataTitleBox>
                                        <div className="MainDataTitleSubBox">
                                            <MainDataTitle title={`나의 건강 대시보드`}/>
                                            <MainDataSubTitle title={`마지막 업데이트 ${user_info?.health_data?.created_at||"-"}`}/>
                                        </div>
                                        <DataBtn addClass="pcContents" func={()=>{navigate(`/healthSurvey/detail`)}}>나의 건강 데이터</DataBtn>
                                    </MainDataTitleBox>
                                    <div className="mainDataHealthInfo">
                                        <MainDataHealthInfoItem
                                            icon="my_icon_0"
                                            name="체중"
                                            type={user_info?.health_data?.bmi ? userWeightChk(user_info?.health_data?.bmi) : null}
                                            val={user_info?.health_data?.weight||"-"}
                                            unit="kg"
                                        />
                                        <MainDataHealthInfoItem
                                            icon="my_icon_1"
                                            name="BMI"
                                            type={user_info?.health_data?.bmi ? bmiChk(user_info?.health_data?.bmi) : null}
                                            val={user_info?.health_data?.bmi||"-"}
                                            unit={null}
                                        />
                                        {user_info?.health_data?.disease === "신장" ? 
                                        <>
                                            <MainDataHealthInfoItem
                                                icon="my_icon_2"
                                                name="Cr"
                                                type={user_info?.health_data?.cr_status||null}
                                                val={user_info?.health_data?.cr_value||"-"}
                                                unit="mb/dL"
                                            />
                                            <MainDataHealthInfoItem
                                                icon="my_icon_3"
                                                name="사구체여과율"
                                                type={user_info?.health_data?.gfr_status||null}
                                                val={user_info?.health_data?.gfr_value||"-"}
                                                unit="%"
                                            />
                                        </>
                                        : user_info?.health_data?.disease === "당뇨" ?
                                        <>
                                            <MainDataHealthInfoItem
                                                icon="my_icon_4"
                                                name="공복 혈당"
                                                type={user_info?.health_data?.fasting_blood_sugar_status||null}
                                                val={user_info?.health_data?.fasting_blood_sugar||"-"}
                                                unit="mg/dL"
                                            />
                                            <MainDataHealthInfoItem
                                                icon="my_icon_5"
                                                name="당화혈색소"
                                                type={user_info?.health_data?.glycated_hemoglobin_status||null}
                                                val={user_info?.health_data?.glycated_hemoglobin||"-"}
                                                unit="%"
                                            />
                                        </>
                                        : user_info?.health_data?.disease === "암" ?
                                        <>
                                            <MainDataHealthInfoItem
                                                icon="my_icon_6"
                                                name="알부민"
                                                type={user_info?.health_data?.albumin_status||"-"}
                                                val={user_info?.health_data?.albumin_value||"-"}
                                                unit="g/dL"
                                            />
                                            <MainDataHealthInfoItem
                                                icon="my_icon_7"
                                                name="헤모글로빈(Hb)"
                                                type={user_info?.health_data?.hb_status||"-"}
                                                val={user_info?.health_data?.hb_value||"-"}
                                                unit="g/dL"
                                            />
                                        </>
                                        : ""}
                                    </div>
                                    <div className="mainDataHealthInfoBtnBox mContents">
                                        <DataBtn func={()=>{navigate(`/healthSurvey/detail`)}}>나의 건강 데이터</DataBtn>
                                    </div>
                                </MainDataArea>
                            </>
                        :
                            <MainDataArea addClass="row4 textCenter">
                                <MainDataTitle title="나의 <span class='oTextColor'>건강 데이터</span>를 등록해 보세요."/>
                                <MainDataSubText text="등록하신 건강 데이터를 기준으로 나의 건강 현황과<br/>하루에 섭취 필요한 영양구성 및 맞춤 식단을 추천해 드려요."/>
                                <div className="mainDataHealthInfo_notDataBtnBox">
                                    <DataBtn func={()=>{
                                        setCustomPopupData({
                                            addClass:null,
                                            title:"가족 선택하기",
                                            closeType:true,
                                            closeFunc:(e) => {setCustomPopupData(null)}
                                        });
                                    }}>가족 선택하기</DataBtn>
                                    <DataBtn func={()=>{navigate(`/healthSurvey`)}}>건강 데이터 등록</DataBtn>
                                </div>
                            </MainDataArea>
                        }
                    </MainDataSection>
                    :""}

                    <SubProductList
                        addClass={target_recipes && target_recipes?.length > 0 ? "" : "subProductList_notItem"}
                        title="나의 건강상태<br/><span class='oTextColor'>맞춤 레시피</span>"
                        text={`지금 ${user_info?.name||"고객"}님의 건강 상태에 적합한 레시피들을 골라봤어요.`}
                        allBtn={true}
                        func={()=>{navigate("/recipe")}}
                        allBtnText={null}
                        btnAddClass=""
                    >
                        <SubProductListSlide data={target_recipes} type="recipe"/>
                    </SubProductList>
                    {care_sub_banner && user_info ? 
                        <MainSubBanner
                            url={care_sub_banner?.url}
                            image_url={care_sub_banner?.image_url}
                        />
                    :""}
                    <SubProductList
                        addClass={customize_products && customize_products?.length > 0 ? "" : "subProductList_notItem"}
                        title="목적 별<br/><span class='oTextColor'>맞춤 레시피</span>"
                        text="저당, 저염 레시피를 찾고계신가요?<br/>목적에 맞는 레시피를 추천드릴게요."
                        allBtn={true}
                        func={()=>{navigate("/recipe")}}
                        allBtnText={null}
                        btnAddClass=""
                        tapVal={localStorage.getItem("token") && user_info ? tap : null}
                        tapFunc={(tapId)=>{if(localStorage.getItem("token") && user_info){setTap(tapId)}}}
                        tap={localStorage.getItem("token") && user_info ? categoryApiData : null}
                    >
                        <SubProductListSlide data={customize_products} type="recipe"/>
                    </SubProductList>
                    {care_sub_banner && !user_info ? 
                        <MainSubBanner
                            url={care_sub_banner?.url}
                            image_url={care_sub_banner?.image_url}
                        />
                    :""}
                    <SubProductList
                        addClass={popular_recipes && popular_recipes?.length > 0 ? "" : "subProductList_notItem"}
                        title="화제의<br/><span class='oTextColor'>인기 레시피</span>"
                        text="다른 분들이 관심갖고 있는 인기있는 레시피들을 추천드릴게요."
                        allBtn={true}
                        func={()=>{navigate("/recipe")}}
                        allBtnText={null}
                        btnAddClass=""
                    >
                        <SubProductListSlide data={popular_recipes} type="recipe"/>
                    </SubProductList>
                </div>
                {localStorage.getItem("token") ? 
                ""
                : 
                <MainNotMember/>
                }
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <FamilyItem
                    id={null}
                    val={familyId}
                    userType={"my"}
                    name={user?.name}
                    gender={user?.gender === "f" ? "여성" : "남성"}
                    birth={`${user?.birth}년`}
                    height={user?.height}
                    weight={user?.weight}
                    func={()=>{
                        setFamilyId(null);
                        window.familyIdSetting("my");
                        setCustomPopupData(null);
                    }}
                />
                {familyApiData && familyApiData?.map((item,i)=>(
                    <FamilyItem
                        key={i}
                        id={item.id}
                        val={familyId}
                        userType={null}
                        name={item.name}
                        gender={item.gender === "f" ? "여성" : "남성"}
                        birth={`${item.birth}년`}
                        height={item.height}
                        weight={item.weight}
                        func={()=>{
                            setFamilyId(item.id);
                            window.familyIdSetting(item.id);
                            setCustomPopupData(null);
                        }}
                    />
                ))}
                <FamilyItemAddBtn func={()=>{navigate("/mypage/family")}}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
            <Footer/>
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default Main;